<template>
  <div>
    <!--top=""-->
    <el-dialog
        :append-to-body="true"
        title="确定成单"
        :visible.sync="subFormASingleData.dialogShow"
        width="500px"
        :close-on-press-escape="false"
    >
      <div class="subFormASingle-dialog">
        <ul>
          <li>
            <span>合同金额</span>
            <el-input v-model="subFormASingleData.contract_money" placeholder="请输入合同金额"></el-input>
          </li>
          <li>
            <span>预收金额</span>
            <el-input v-model="subFormASingleData.advance_money" placeholder="请输入合同金额"></el-input>
          </li>
          <li>
            <span>预收日期</span>
            <el-date-picker
                v-model="subFormASingleData.advance_date" placeholder="预约日期" value-format="timestamp"
                dateType="time">
            </el-date-picker>
          </li>
          <li>
            <span>约定发货日期</span>
            <el-date-picker
                v-model="subFormASingleData.deliver_date" placeholder="约定发货日期" value-format="timestamp"
                dateType="time">
            </el-date-picker>
          </li>
          <li>
            <span>余款</span>
            <el-input v-model="subFormASingleData.balance" placeholder="请输余额"></el-input>
          </li>
          <li>
            <span>描述</span>
            <el-input
                type="textarea"
                :autosize="{ minRows: 4}"
                placeholder="请输入描述"
                v-model="subFormASingleData.finish_desc">
            </el-input>
          </li>
          <li>
            <span>附件</span>
            <el-upload
                :action="annexAction"
                :show-file-list="false"
                list-type="text"
                :auto-upload="true"
                :before-upload="annexUpload"
                :on-error="annexError"
                :on-success="annexSuccess"
                :multiple="false"
                :limit="9"
                :on-exceed="annexExceed"
                name="file"
                :data="annexData"
            >
              <el-button size="small" type="primary" style="margin-right: 8px">点击上传</el-button>
            </el-upload>
            <div class="content-main-annex">
              <ul>
                <li v-for="(item,key) in subFormASingleData.finish_attachment" :key="key">
                  <span>{{ item.name }}</span>
                  <i @click="annexDel(key)" class="del">删除</i>
                  <i @click="annexDownload(item.url)">下载</i>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div slot="footer">
        <el-button @click="subFormASingleData.dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="subFormDialogClick">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['subFormASingleData'],
  data() {
    return {
      //附件
      annexAction: process.env.VUE_APP_URL + '/api/upload/uploadFile',
      annexData: {remarks: 1}, //上传添加的字段
      annexName: '', //上传文件成功后文件的名称
    };
  },
  mounted() {
  },
  methods: {
    subFormDialogClick() {
      this.$emit('subFormDialogClick')
    },
    //附件下载
    annexDownload(url) {
      window.location = url
    },
    //附件 移除文件时的钩子
    annexDel(key) {
      this.$confirm('此操作将删除该文件, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.subFormASingleData.finish_attachment.splice(key, 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },
    //附件 上传失败的钩子
    annexError() {
      this.$message.error('附件上传失败上传失败');
    },
    //附件 上传成功钩子
    annexSuccess(file) {
      let data = {
        name: this.annexName,
        url: file.data
      }
      this.subFormASingleData.finish_attachment.push(data)
    },
    //附件 上传文件之前的钩子
    annexUpload(file) {
      this.annexName=file.name;//名字存起来，传给后台要用
      /*const isText = (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
      if (!isText) {
        this.$message.error('上传附件格式错误!');
      }
      return file;*/
    },
    //附件 文件超出个数限制时的钩子
    annexExceed(files, fileList) {
      this.$message.error(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
  },
};
</script>

<style lang="scss" scoped>
.subFormASingle-dialog {
  li:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  li {
    @include flex();
    margin-bottom: 12px;

    > span {
      width: 90px;
      flex: 0 0 auto;
    }

    .el-date-editor {
      flex: 1;
    }
  }
}
</style>
