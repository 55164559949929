<template>
  <div class="content-tile">
    <div class="su-title">
      <i class="el-icon-document-copy"></i>
      <span>询单详细</span>
    </div>
    <div>
      <template v-for="(item,key) in buttonName">
        <!--  <template v-if="$store.state.currentPermissions!=3">
            <el-button
                :key="key"
                :type="item.type"
                @click="methodName(item.methodName)"
                v-if="$store.state.currentButton.indexOf(key)!=-1"
            >
              {{ item.name }}
            </el-button>
          </template>-->
        <!--采购端-->
        <template v-if="$store.state.currentPermissions==1">
          <el-button
              :key="key"
              :type="item.type"
              @click="methodName(item.methodName)"
              v-if="$store.state.currentButton.indexOf(key)!=-1"
          >
            {{ item.name }}
          </el-button>
        </template>
        <!--销售端-->
        <template v-if="$store.state.currentPermissions==2">
          <el-button
              :key="key"
              :type="item.type"
              @click="methodName(item.methodName)"
              v-if="$store.state.currentButton.indexOf(key)!=-1"
          >
            {{ item.name }}
          </el-button>
        </template>
        <!--成本中心-->
        <template v-if="$store.state.currentPermissions==3">

          <template v-if="$store.state.currentButton.indexOf(key)!=-1 && !item.jurisdiction3">
            <!--新询单列表-->
            <template v-if="$route.name=='inquiryList'">
              <el-button
                  :key="key"
                  :type="item.type"
                  @click="methodName(item.methodName)"
                  v-if="$store.state.routerRole.indexOf(item.inquiryList3Id)!=-1 || $store.state.routerRole.indexOf('1')!=-1"
              >
                {{ item.name }}
              </el-button>
            </template>
            <!--待报价列表列表-->
            <template v-if="$route.name=='pendingQuotationList'">
              <el-button
                  :key="key"
                  :type="item.type"
                  @click="methodName(item.methodName)"
                  v-if="$store.state.routerRole.indexOf(item.pendingQuotationList3Id)!=-1 || $store.state.routerRole.indexOf('1')!=-1"
              >
                {{ item.name }}
              </el-button>
            </template>
            <!--已报价列表列表-->
            <template v-if="$route.name=='quotedList'">
              <el-button
                  :key="key"
                  :type="item.type"
                  @click="methodName(item.methodName)"
                  v-if="$store.state.routerRole.indexOf(item.quotedList3Id)!=-1 || $store.state.routerRole.indexOf('1')!=-1"
              >
                {{ item.name }}
              </el-button>
            </template>

          </template>


        </template>
      </template>
      <!--采购端导出-->
      <template v-if="$store.state.currentPermissions==1">
        <el-button
            type="info"
            @click="$emit('exportMethod')"
        >导出
        </el-button>
      </template>
      <!--销售端导出按钮-->
      <template v-if="$store.state.currentPermissions==2">
        <el-button
            type="info"
            v-if="$route.name=='inquiryList' || $route.name=='quotedList'"
            @click="$emit('exportMethod')"
        >导出
        </el-button>
      </template>
      <!--成本中心导出-->
      <template v-if="$store.state.currentPermissions==3">
        <!--新询单列表-->
        <template v-if="$route.name=='inquiryList'">
          <el-button
              type="info"
              @click="$emit('exportMethod')"
              v-if="$store.state.routerRole.indexOf('32')!=-1 || $store.state.routerRole.indexOf('1')!=-1"
          >导出
          </el-button>
        </template>
        <!--待报价列表列表-->
        <template v-if="$route.name=='pendingQuotationList'">
          <el-button
              type="info"
              @click="$emit('exportMethod')"
              v-if="$store.state.routerRole.indexOf('36')!=-1 || $store.state.routerRole.indexOf('1')!=-1"
          >导出
          </el-button>
        </template>
        <!--已报价列表列表-->
        <template v-if="$route.name=='quotedList'">
          <el-button
              type="info"
              @click="$emit('exportMethod')"
              v-if="$store.state.routerRole.indexOf('40')!=-1 || $store.state.routerRole.indexOf('1')!=-1"
          >导出
          </el-button>
        </template>
      </template>
    </div>
    <el-dialog
        :append-to-body="true"
        ref="table"
        title="报价信息"
        :visible.sync="sendQuoteDialog"
        width="1000px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <el-table
          :data="tableData"
          border
          :header-cell-style="{
                      'color': '#18a78a'}"
          style="width: 100%">
        <el-table-column
            label="品牌（中文）"
            prop="brand_ch"
            min-width="120px"
            align="left"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            label="品牌（英文）"
            min-width="120px"
            align="left"
            prop="brand_en"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            label="品名/规格型号/单位"
            min-width="140px"
            align="left"
            prop="spec"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            label="数量"
            min-width="120px"
            align="left"
            prop="number"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            label="销售单价"
            min-width="120px"
            prop="unitPrice"
            align="left"
        >
        </el-table-column>
        <el-table-column
            label="销售总价"
            min-width="120px"
            prop="totalSalePrice"
            align="left"
        >
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sendQuoteDialog=false">取 消</el-button>
        <el-button type="primary" @click="$emit('sendQuoteClick')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['tableData'],
  data() {
    return {
      sendQuoteDialog:false,//点击提交dialog
      buttonName: [{
        name: '作废',
        type: 'warning',
        methodName: 'delBut',
        inquiryList3Id: "30",//成本中心，新询单列表 做权限是否显示
        pendingQuotationList3Id:'34',//成本中心，待报价列表 做权限是否显示
        quotedList3Id:'38'
        //  0
      }, {
        name: '发送报价',
        type: 'primary',
        methodName: 'sendQuote',
        inquiryList3Id: "31",//成本中心，新询单列表 做权限是否显示
        pendingQuotationList3Id:'35',//成本中心，待报价列表 做权限是否显示
        quotedList3Id:'39'
        //  1
      }, {
        name: '确定询单',
        type: 'primary',
        methodName: 'subBut',
        jurisdiction3: true, //产品中心不要确定询单
        //  2
      }, {
        name: '操作',
        type: 'primary',
        methodName: 'invalid',
        //  3
      }, {
        name: '提交',
        type: 'primary',
        methodName: 'invalid',
        //  4
      }, {
        name: '修改',
        type: 'info',
        methodName: 'editBut',
        //  5
      }, {
        name: '确定成单',
        type: 'primary',
        methodName: 'subForm',
        // 6
      }/*, {
        name: '导出',
        type: 'info',
        methodName: 'invalid',
        //  6
      }*/
      ]
    };
  },
  mounted() {
  },
  methods: {
    // 作废
    delBut() {
      this.$emit('delButClick')
    },
    //权限3发送报价
    sendQuote() {
      this.sendQuoteDialog=true; //弹窗显示
      // this.$emit('sendQuoteClick')
    },

    //权限2修改
    editBut() {
      this.$emit('editButClick')
    },
    //权限2 确定成单
    subForm() {
      this.$emit('subFormClick')
    },
    //权限1 确定询单
    subBut() {
      this.$emit('subButClick')
    },
    //循环出来的自己找方法
    methodName(name) {
      this[name]();
    }
  },
};
</script>

<style lang="scss" scoped></style>
