<!--历史导入dialog-->
<template>
  <div>
    <el-dialog
        :append-to-body="true"
        ref="table"
        title="历史报价导入"
        :visible.sync="historyImportData.historyImportDialog"
        width="1000px"
        :before-close="historyImportClose"
        :close-on-press-escape="false"
    >

      <div class="historyImport-dialog">
        <!--<div class="search">
          <el-input
              placeholder="请输入"
              suffix-icon="el-icon-search"
              v-model="historyImportData.search">
          </el-input>
        </div>-->
        <el-table
            ref="table"
            :data="historyImportData.tableData"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :row-class-name="tableRowClassName"
            :header-cell-style="{
                      'color': '#18a78a'}"
        >
          <el-table-column type="selection"/>
          <el-table-column
              min-width="100px"
              align="center"
              v-for="(item,key) in tableTile"
              :key="key"
              :label="item.title"
              :prop="item.val"
          />
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="historyImportData.current_page"
            :page-size="30"
            layout="total, prev, pager, next"
            :total="historyImportData.total"
            style="margin-top: 10px"
        >
        </el-pagination>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="historyImportData.historyImportDialog=false">取 消</el-button>
        <el-button type="primary" @click="listDialogSub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['tableData', 'historyImportData'],
  data() {
    return {
      selectData: null, //单选 获去当前的列的值
      tableTile: [{
        title: '品牌（中文）',
        val: 'brand_ch'
      }, {
        title: '品牌（英文）',
        val: 'brand_en'
      }, {
        title: '品名/规格型号/单位',
        val: 'spec'
      }, {
        title: '折后单价',
        val: 'discountUnitPrice'
      }, {
        title: '币种',
        val: 'currency'
      }, {
        title: '货期',
        val: 'deliveryTime'
      }, {
        title: '货期单位',
        val: 'deliveryUnit'
      }, {
        title: '报价日期',
        val: 'offerDate'
      }]
    };
  },
  mounted() {
  },
  methods: {
    //历史导入分页
    handleCurrentChange(val) {
      this.historyImportData.current_page = val;
      this.$emit('historyImportMethods')
      console.log(`当前页: ${val}`);
    },
    //表格 单选
    handleSelectionChange(selection) {
      if (selection.length == 0) {
        this.historyImportData.selectData = null;
        return false;
      }
      // 单选
      if (selection.length > 1) {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleRowSelection(selection.pop());
      } else {
        selection.forEach((value) => {
          this.historyImportData.tableData.forEach((val) => {
            if (value.id == val.id) {
              this.historyImportData.selectData = value;
            }
          });
        });
      }
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
    //dialog 关闭
    historyImportClose() {
      this.historyImportData.historyImportDialog = false;
      this.historyImportData.selectData = null;
      this.$refs.table.clearSelection(); // 清空选择的值
    },
    //dialog 点击确定
    listDialogSub() {
      let allData=this.tableData[this.historyImportData.index];//获取当前条数总数
      let mainData = this.tableData[this.historyImportData.index].list[this.historyImportData.key]; //获取单前数据
      let historyImportDataSelectData = this.historyImportData.selectData;//获取单选那条的数据
      let numberOne=allData.number;
      if(historyImportDataSelectData==null){
        this.$message.error('请选择一条历史报价');
        return false;
      }
      // grossWeight //总数量
      mainData.discountUnitPrice = historyImportDataSelectData.discountUnitPrice;//折后单价
      mainData.currency = historyImportDataSelectData.currency;//币种
      mainData.discount = historyImportDataSelectData.discount;//折扣
      mainData.unitWeight = historyImportDataSelectData.unitWeight;//单件重量
      mainData.unitOfWeight = historyImportDataSelectData.unitOfWeight;//重量单位
      mainData.packageDimensions = historyImportDataSelectData.packageDimensions;//包装尺寸
      mainData.packingUnit = historyImportDataSelectData.packingUnit;//包装位
      mainData.deliveryTime = historyImportDataSelectData.deliveryTime;//货期单
      mainData.deliveryUnit = historyImportDataSelectData.deliveryUnit;//货期单位
      mainData.totalPrice = numberOne*historyImportDataSelectData.discountUnitPrice;//总价
      mainData.grossWeight = numberOne*historyImportDataSelectData.unitWeight;//总重量
      mainData.offerDate = historyImportDataSelectData.offerDate;//报价日期
      this.historyImportClose();
    }
  },
};
</script>

<style lang="scss">
.historyImport-dialog .search {
  margin-bottom: 12px;

  .el-icon-search {
    color: #05c69a;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
