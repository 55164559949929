<!--报价附件-->
<template>
  <div class="annex-upload">
    <el-checkbox
        v-model="quotationAttachment.check"
        class="mb-12"
        v-show="false"
    >
      销售可见
    </el-checkbox>
    <el-upload
        :action="annexAction"
        :show-file-list="false"
        list-type="text"
        :auto-upload="true"
        :before-upload="annexUpload"
        :on-error="annexError"
        :on-success="annexSuccess"
        :multiple="false"
        :limit="9"
        :on-exceed="annexExceed"
        name="file"
        :data="annexData"
        v-if="$store.state.currentPermissions!=2"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  <!--  <template v-if="$store.state.currentPermissions==2">
      <div class="content-main-annex" v-if="quotationAttachment.check">
        <ul>
          <li v-for="(item,key) in quotationAttachment.attachment" :key="key">
            <span>{{ item.name }}</span>
            <i @click="annexDel(key)" class="del">删除</i>
            <i @click="annexDownload(item.url)">下载</i>
          </li>
        </ul>
      </div>
    </template>-->
    <div class="content-main-annex">
      <ul>
        <li v-for="(item,key) in quotationAttachment.attachment" :key="key">
          <span>{{ item.name }}</span>
          <i @click="annexDel(key)" class="del">删除</i>
          <i @click="annexDownload(item.url)">下载</i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['quotationAttachment'],
  data() {
    return {
      //附件
      annexAction: process.env.VUE_APP_URL + '/api/upload/uploadFile',
      annexData: {remarks: 1}, //上传添加的字段
      annexName: '', //上传文件成功后文件的名称
    };
  },
  mounted() {
  },
  methods: {
    //附件下载
    annexDownload(url) {
      window.open(url)
    },
    //附件 移除文件时的钩子
    annexDel(key) {
      this.$confirm('此操作将删除该文件, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.quotationAttachment.attachment.splice(key, 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },
    //附件 上传失败的钩子
    annexError() {
      this.$message.error('附件上传失败');
    },
    //附件 上传成功钩子
    annexSuccess(file) {
      let data = {
        name: this.annexName,
        url: file.data
      }
      this.quotationAttachment.attachment.push(data)
    },
    //附件 上传文件之前的钩子
    annexUpload(file) {
      this.annexName=file.name;//名字存起来，传给后台要用
      const isText =  (file.type === 'application/vnd.ms-excel' || file.type === 'application/pdf' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type ==='application/msword' || file.type ==='text/plain' || file.type ==='application/vnd.openxmlformats-officedocument.wordprocessingml.document');
      if (!isText) {
        this.$message.error('上传附件格式错误!');
      }
      return file;
    },
    //附件 文件超出个数限制时的钩子
    annexExceed(files, fileList) {
      this.$message.error(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },

  },
};
</script>

<style lang="scss" scoped></style>
