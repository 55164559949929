<template>
  <div>
    <div class="content-main-title">
      <span>订单跟踪记录</span>
      <div class="hr"></div>
    </div>
    <el-table
        ref="table"
        :data="orderRecord"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
                      'color': '#18a78a'}"
    >
      <el-table-column
          label="操作"
          prop="operate"
          min-width="120px"
          align="left"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          label="记录日期"
          prop="recordDate"
          min-width="160px"
          align="left"
      >
      </el-table-column>
      <el-table-column
          label="操作人"
          prop="operator"
          min-width="120px"
          align="left"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          label="描述"
          prop="description"
          min-width="120px"
          align="left"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          label="附件"
          prop="annex"
          min-width="120px"
          align="left"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
            <ul class="order-record">
              <li v-for="(item,key) in scope.row.annex" :key="key">
                {{ item.name }}
                <i @click="annexDownload(item.url)">下载</i>
              </li>
            </ul>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['orderRecord'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    //附件下载
    annexDownload(url) {
      window.location = url
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.order-record{
  li{
    margin-bottom: 5px;
  }
  i{
    display: inline-block;
    background:#1a9d00;
    padding: 2px 5px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    margin-left: 8px;
  }
}
</style>
