<!--是否是历史询单-->
<template>
  <div v-if="$store.state.currentPermissions!=2" class="isHistorical-Inquiry">
    <span>是否历史询单</span>
    <div>
      <el-radio v-model="inquiryDetailedTile.isHistoricalQuote" :label="true">是</el-radio>
      <el-radio v-model="inquiryDetailedTile.isHistoricalQuote" :label="false">否</el-radio>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['inquiryDetailedTile'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.isHistorical-Inquiry {
  @include flex();

  > span {
color: #7f8789;
  }

  > div {
    margin-left: 12px;
  }
}
</style>
