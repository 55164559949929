<!--表格操作-->
<template>
  <div class="table-operating">
    <ul v-for="(ite,ke) in tableOperating" :key="ke">
      <li :key="ke">报价{{ ke + 1 }}</li>
      <template v-for="(item,key) in tableOperating[ke]">

        <li :key="key" v-if="item.show.indexOf($store.state.currentPermissions) !=-1">
          <span>{{ item.name }}</span>
          <el-input
              v-model="item.val"
              placeholder="请输入"
              @change="changeVal(item.name,item.enName,item.val,ke)"
              v-if="item.selectOptions==undefined"
          >
          </el-input>
          <el-select v-model="item.val" placeholder="请选择" v-else style="width: 100px"
                     @change="changeVal(item.name,item.enName,item.val,ke)">
            <el-option
                v-for="itemOptions in item.selectOptions"
                :key="itemOptions.value"
                :label="itemOptions.label"
                :value="itemOptions.value">
            </el-option>
          </el-select>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['tableOperating', 'tableData'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    // 值改变后
    changeVal(name, enName, val, ke) {
      this.$confirm(`批量操作<span style="color: red">${name}</span>, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'warning',
          message: '操作成功,请自行保存!'
        });
        //采购端
        if (this.$store.state.currentPermissions == 1) {
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].list[ke][enName] = val;
            //获取数量
            let number = this.tableData[i].number;
            //总价
            if (enName == 'discountUnitPrice') {
              this.tableData[i].list[ke].totalPrice = val * number;
            }
            //总重量
            if (enName == 'unitWeight') {
              this.tableData[i].list[ke].grossWeight = val * number;
            }
          }
        } else {//成本中心
          let tariffRate = 0;//关税率
          let vatRate = 0;//增值税率
          for (let i = 0; i < this.tableOperating[0].length; i++) {
            if (this.tableOperating[0][i].enName == 'tariffRate') {
              tariffRate = this.tableOperating[0][i].val;
            }
            if (this.tableOperating[0][i].enName == 'vatRate') {
              vatRate = this.tableOperating[0][i].val;
            }
          }
          let number = 0;
          for (let i = 0; i < this.tableData.length; i++) {
            //获取总数量
            number += this.tableData[i].number;
          }
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i][enName] = val
            //获取折后单价
            let discountUnitPrice = this.tableData[i].list[this.tableData[i].tableBuyerSelect].discountUnitPrice;
            //汇率
            let exchangeRate = this.tableData[i].exchangeRate;
            switch (enName) {
              case 'exchangeRate': //汇率
                //计算 换算单价 2位
                this.tableData[i].convertedUnitPrice = (discountUnitPrice * exchangeRate).toFixed(2);
                break
              case 'internationalShipping': //国际总运费
                //国际运费 1位
                this.tableData[i].internationalShipping = (val / number).toFixed(1);
                break
              case 'bankFees'://银行手续费
                this.tableData[i].bankFees = (val / number).toFixed(0);
                break
              case 'otherFee'://其他费用
                this.tableData[i].otherFee = (val / number).toFixed(0);
                break
              case 'domesticFreight'://国内运费
                this.tableData[i].domesticFreight = (val / number).toFixed(0);
                break
            }
            //换算单价
            let convertedUnitPrice = this.tableData[i].convertedUnitPrice == null ? 0 : this.tableData[i].convertedUnitPrice
            //计算成本
            //国际运费
            let internationalShipping = this.tableData[i].internationalShipping == null ? 0 : this.tableData[i].internationalShipping;
            //银行手续费
            let bankFees = this.tableData[i].bankFees == null ? 0 : this.tableData[i].bankFees;
            //其他费用
            let otherFee = this.tableData[i].otherFee == null ? 0 : this.tableData[i].otherFee
            //国内运费
            let domesticFreight = this.tableData[i].domesticFreight == null ? 0 : this.tableData[i].domesticFreight;
            //毛利率
            let profit_rate = this.tableData[i].profit_rate == null ? 0 : this.tableData[i].profit_rate;
            //成本
            let cost = (((Number(convertedUnitPrice) + Number(internationalShipping) + Number(bankFees) + Number(otherFee)) * (1 + Number(tariffRate)) + Number(domesticFreight)) * (1 + Number(vatRate))).toFixed(2);
            //关税
            this.tableData[i].tariff = ((Number(convertedUnitPrice) + Number(internationalShipping) + Number(bankFees) + Number(otherFee)) * Number(tariffRate)).toFixed(2);
            //增值税
            this.tableData[i].vat = (((Number(convertedUnitPrice) + Number(internationalShipping) + Number(bankFees) + Number(otherFee)) * (1 + Number(tariffRate)) + Number(domesticFreight)) * Number(vatRate)).toFixed(2)
            //毛利
            this.tableData[i].grossProfit = (cost * profit_rate).toFixed(2);
            //销售单价
            this.tableData[i].unitPrice = (Number(cost) + Number(this.tableData[i].grossProfit)).toFixed(2);
            //销售总价
            this.tableData[i].totalSalePrice = (this.tableData[i].unitPrice * this.tableData[i].number).toFixed(2);
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.table-operating {
  ul {
    @include flex(row, wrap, center, flex-start);

    li {
      @include flex();
      padding-top: 8px;

      > span {
        padding-left: 8px;
        flex: 0 0 auto;
        text-align: right;
        padding-right: 8px;
        color: #7f8789;
      }

      .el-input {
        width: 100px;
        //padding: 0 8px;
        flex: 0 0 auto
      }
    }
  }
}
</style>
