<!--选择采购员dialog 多选-->
<template>
  <div>
    <div class="inquiryIn-formation-buyer">
      <span>采购员</span>
      <el-button type="primary" @click="buyer.buyerSelectDialog=true">选择</el-button>
      <ul class="inquiryIn-formation-buyer pl-12">
        <template v-for="(item,key) in buyer.buyer_list">
          <li :key="key" v-if="buyer.buyer_ids.indexOf(item.id)!==-1">
            <span>{{ item.name }}</span>
            <i class="el-icon-close" @click="buyerIsSelect(item.id)"></i>
          </li>
        </template>
      </ul>
    </div>

    <!--dialog采购员-->
    <el-dialog
        :append-to-body="true"
        title="选择采购员"
        :visible.sync="buyer.buyerSelectDialog"
        width="440px"
        :show-close="false"
        :before-close="buyerClose"
        :close-on-press-escape="false"
    >
      <div class="buyerSelect-dialog">
        <div class="title">
          <span>已选择：</span>
          <ul>
            <template v-for="(item,key) in buyer.buyer_list">
              <li :key="key" v-if="buyer.buyer_ids.indexOf(item.id)!==-1">
                <span>{{ item.name }}</span>
                <i class="el-icon-close" @click="buyerIsSelect(item.id)"></i>
              </li>
            </template>
          </ul>
        </div>

        <div class="search">
          <el-input
              placeholder="请输入采购员名称"
              suffix-icon="el-icon-search"
              v-model="buyer.username">
          </el-input>
        </div>

        <div class="list">
          <el-checkbox-group v-model="buyer.buyer_ids">
            <template v-for="(item) in buyer.buyer_list">
              <el-checkbox
                  :label="item.id"
                  :key="item.id"
              >
                {{ item.name }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <!--<el-button @click="buyerClose">取 消</el-button>-->
        <el-button type="primary" @click="buyer.buyerSelectDialog = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['buyer'],
  data() {
    return {};
  },
  mounted() {

  },
  methods: {
    //采购员  选择采购员关闭dialog还原数据
    buyerClose() {
      this.$emit('buyerParentClose')
    },
    //采购员 删除选择
    buyerIsSelect(id) {
      for (let i = 0; i < this.buyer.buyer_ids.length; i++) {
        if (this.buyer.buyer_ids[i] == id) {
          this.buyer.buyer_ids.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

//采购员选择
.inquiryIn-formation-buyer {
  @include flex(row, wrap, center, flex-start);

  > span {
    text-align: left;
    width: 80px;
    color: #7f8789;
    flex: 0 0 auto;
  }

  li {
    margin:8px 8px 0 0;
  }

  i {
    color: red;
    margin-left: 8px;
    cursor: pointer;
  }
}
//选择采购员
.buyerSelect-dialog {
  .title {
    @include flex();

    & > span {
      width: 60px;
      flex: 0 0 auto;
    }

    ul {
      @include flex(row, wrap, center, flex-start);

      li {
        margin-right: 12px;

        i {
          margin-left: 5px;
          color: red;
          cursor: pointer;
        }
      }
    }
  }

  .search {
    margin-top: 12px;

    .el-icon-search {
      color: #05c69a;
      font-size: 16px;
    }
  }

  .list {
    .el-checkbox:nth-of-type(even) {
      background: #C9F5F2;
    }

    .el-checkbox-group {
      background: #E3FEFB;
      margin-top: 8px;
    }

    .el-checkbox {
      @include flex();
      width: calc(100% - 16px);
      padding: 8px;
    }
  }
}
</style>
