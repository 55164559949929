<!--表格数据-->
<template>
  <div class="mt-12" style="width: 100%">
    <el-table
        ref="table"
        :data="tableData"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
                      'color': '#18a78a'}"
        @selection-change="singleSelection"
    >
      <el-table-column
          type="selection"
          width="55"
          v-if="$route.name=='quotedList' && $store.state.currentPermissions==2"
      >
      </el-table-column>


      <el-table-column
          label="品牌（中文）"
          prop="brand_ch"
          min-width="120px"
          align="left"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span v-if="$store.state.currentPermissions!=2">
                {{ scope.row.brand_ch }}
          </span>
          <el-input
              v-model="scope.row.brand_ch"
              placeholder="请输入"
              v-if="$store.state.currentPermissions==2"
          >
          </el-input>
        </template>
      </el-table-column>

      <el-table-column
          label="品牌（英文）"
          min-width="120px"
          align="left"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span v-if="$store.state.currentPermissions!=2">
                {{ scope.row.brand_en }}
          </span>
          <el-input
              v-model="scope.row.brand_en"
              placeholder="请输入"
              v-if="$store.state.currentPermissions==2"
          >
          </el-input>
        </template>
      </el-table-column>

      <el-table-column
          label="品名/规格型号/单位"
          min-width="140px"
          align="left"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span v-if="$store.state.currentPermissions!=2">
                {{ scope.row.spec }}
          </span>
          <el-input
              v-model="scope.row.spec"
              placeholder="请输入"
              v-if="$store.state.currentPermissions==2"
          >
          </el-input>
        </template>
      </el-table-column>

      <el-table-column
          label="数量"
          min-width="120px"
          align="left"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span v-if="$store.state.currentPermissions!=2">
                {{ scope.row.number }}
          </span>
          <el-input
              v-model="scope.row.number"
              placeholder="请输入"
              v-if="$store.state.currentPermissions==2"
          >
          </el-input>
        </template>
      </el-table-column>
      <!--权限2才要-->
      <el-table-column
          label="销售单价"
          min-width="120px"
          align="left"
          v-if="$store.state.currentPermissions==2"
      >
        <template slot-scope="scope">
          <el-input
              v-model="scope.row.unitPrice"
              placeholder="请输入"
              @change="unitPriceChange(scope.row)"
          >
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
          label="销售总价"
          min-width="120px"
          align="left"
          v-if="$store.state.currentPermissions==2"
      >
        <template slot-scope="scope">
          <el-input
              v-model="scope.row.totalSalePrice"
              placeholder="请输入"
          >
          </el-input>
        </template>
      </el-table-column>


      <el-table-column
          label="采购员"
          min-width="120px"
          align="left"
          v-if="$store.state.currentPermissions==3"
      >
        <template slot-scope="scope">
          {{ scope.row.$index }}
          <el-select v-model="tableBuyerId1" placeholder="请选择" @change="tableBuyerIdChange">
            <el-option
                v-for="(item,key) in tableBuyer"
                :key="key"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <template v-for="(itm,index) in listTile">
        <el-table-column
            min-width="120px"
            align="center"
            :key="index"
            :label="itm.title"
            v-if="itm.competence.indexOf($store.state.currentPermissions)!=-1"
        >

          <template slot-scope="scope">
            <!--权限1-->
            <div v-if="$store.state.currentPermissions==1">
              <ul class="table-input">
                <li v-for="(item,key) in scope.row.list" :key="key">
                  <el-input
                      v-model="item[itm.field]"
                      placeholder="请输入"
                      disabled
                      v-if="itm.edit==1"
                  >
                  </el-input>
                  <el-input
                      v-model="item[itm.field]"
                      placeholder="请输入"
                      v-if="itm.edit!=1"
                      @change="totalPrice(scope.$index,key,itm.field)"
                  >
                  </el-input>
                </li>
              </ul>
            </div>

            <!--权限2-->
            <div v-if="$store.state.currentPermissions==2">
              <ul class="table-input">
                <li>
                  <el-input
                      v-model="scope.row.list[0][itm.field]"
                      placeholder="请输入"
                      disabled
                      v-if="itm.edit==1"
                  >
                  </el-input>
                  <el-input
                      v-model="scope.row.list[0][itm.field]"
                      placeholder="请输入"
                      v-if="itm.edit!=1"
                      @change="totalPrice(scope.$index,key,itm.field)"
                  >
                  </el-input>
                </li>
              </ul>
            </div>

            <!--权限3-->
            <div v-if="$store.state.currentPermissions==3">
               <span v-if="itm.selectShow!==1">
                {{ scope.row.list[scope.row.tableBuyerSelect][itm.field] }}
              </span>
              <el-select
                  v-model="scope.row.tableBuyerSelect"
                  placeholder="请选择"
                  v-if="itm.selectShow===1"
                  @change="$emit('tableBuyerSelectChange',scope.row.tableBuyerSelect,scope.row.list[scope.row.tableBuyerSelect].sort,scope.row)"
              >
                <el-option
                    v-for="(item3,key3) in scope.row.list"
                    :key="key3"
                    :label="item3.discountUnitPrice"
                    :value="key3">
                </el-option>
              </el-select>

              <el-input
                  v-model="scope.row[itm.field]"
                  placeholder="请输入"
                  v-if="itm.edit==2"
              >
              </el-input>
              <el-input
                  v-model="scope.row[itm.field]"
                  placeholder="请输入"
                  disabled
                  v-if="itm.edit==3"
              >
              </el-input>
              <!--     <el-input
                       v-model="scope.row[itm.field]"
                       placeholder="请输入"
                       disabled
                       v-if="itm.edit==1"
                   >
                   </el-input>-->
            </div>

          </template>
        </el-table-column>
      </template>

      <!--操作-->
      <el-table-column
          min-width="200px"
          align="center"
          label="操作"
      >

        <template slot-scope="scope">
          <!--权限1-->
          <ul class="table-input" v-if="$store.state.currentPermissions==1">
            <li v-for="(item,key) in scope.row.list" :key="key">
              <el-button type="danger" @click="tableDel(1,scope.$index,key)">
                删除
              </el-button>
              <el-button type="primary" @click="historyImport(1,scope.$index,key,scope.row),1">
                历史导入
              </el-button>
            </li>
          </ul>
          <!--权限2-->
          <div v-if="$store.state.currentPermissions==2">
            <template v-if="$route.name!='quotedList'">
              <el-button type="danger" @click="tableDel(2,scope.$index)">
                删除
              </el-button>
            </template>
            <!-- <el-button type="primary" @click="historyImport(2,scope.$index)">
               历史导入
             </el-button>-->
          </div>
          <!--权限3-->
          <div v-if="$store.state.currentPermissions==3">
            <el-button type="danger" @click="tableDel(3,scope.$index)">
              删除
            </el-button>
            <!--    <el-button type="primary" @click="historyImport(3,scope.$index)">
                  历史导入
                </el-button>-->
          </div>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>


export default {
  components: {},
  props: [
    'tableData',
    'tableBuyerId',
    'tableBuyer',
    'historyImportData',
    'supplierData',
    'singleSelectionProp',
      'tableOperating'
  ],
  data() {
    return {
      tableBuyerId1: this.tableBuyerId,
      //循环表单
      listTile: [{
        title: '折后单价',
        field: 'discountUnitPrice',
        competence: [1, 3], //权限
        selectShow: 1, // 权限3 这个要下拉
      }, {
        title: '币种',
        field: 'currency',
        competence: [1, 3] //权限
      }, {
        title: '折扣',
        field: 'discount',
        competence: [1, 3] //权限
      }, {
        title: '总价',
        field: 'totalPrice',
        edit: 1, // 为1就不是输入框 权限1
        competence: [1, 3] //权限
      }, {
        title: '单件重量',
        field: 'unitWeight',
        competence: [1, 3] //权限
      }, {
        title: '重量单位',
        field: 'unitOfWeight',
        competence: [1, 3] //权限
      }, {
        title: '总重量',
        field: 'grossWeight',
        edit: 1,// 为1就不是输入框 2是输入框 3是权限3才要用
        competence: [1, 3] //权限
      }, {
        title: '包装尺寸',
        field: 'packageDimensions',
        competence: [1, 3] //权限
      }, {
        title: '包装单位',
        field: 'packingUnit',
        competence: [1, 3] //权限
      }, {
        title: '货期',
        field: 'deliveryTime',
        competence: [1, 2, 3] //权限
      }, {
        title: '货期单位',
        field: 'deliveryUnit',
        competence: [1, 2, 3] //权限
      }, {
        title: '汇率',
        field: 'exchangeRate',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '换算单价',
        field: 'convertedUnitPrice',
        edit: 3, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '国际运费',
        field: 'internationalShipping',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '银行手续费',
        field: 'bankFees',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '关税',
        field: 'tariff',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '增值税',
        field: 'vat',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '其他费用',
        field: 'otherFee',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '国内运费',
        field: 'domesticFreight',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '毛利率',
        field: 'profit_rate',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '毛利',
        field: 'grossProfit',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '销售单价',
        field: 'unitPrice',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }, {
        title: '销售总价',
        field: 'totalSalePrice',
        edit: 2, // 权限3 为2是输入框
        competence: [3] //权限
      }
      ]
    };
  },
  watch: {
    tableBuyerId(newVal) {
      this.tableBuyerId1 = newVal
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    //选择成单数据
    singleSelection(selection) {
      let tableDataROW = [];
      selection.forEach((value) => {
        this.tableData.forEach((val) => {
          if (value.id == val.id) {
            tableDataROW.push(value.id);
          }
        });
      });
      //把多选完后的数组保存起来
      this.singleSelectionProp.ids = tableDataROW;
    },
    //权限2 销售单价改变总价改变
    unitPriceChange(row) {
      row.totalSalePrice = row.unitPrice * row.number;
    },
    //切换采购员
    tableBuyerIdChange(val) {
      this.$emit('tableBuyerIdChange', val)
    },
    //历史导入 单行
    historyImport(competence, index, key, row) {
      // console.log(this.tableData) 数据
      // console.log(index) 选择总行第几条
      // console.log(key)  选择了具体第几行

      this.historyImportData.params.brand_ch = row.brand_ch;
      this.historyImportData.params.brand_en = row.brand_en;
      this.historyImportData.params.spec = row.spec;
      this.historyImportData.current_page = 1;
      this.historyImportData.index = index;
      this.historyImportData.key = key;
      this.historyImportData.selectData = null;
      this.$emit('historyImportMethods')
      this.historyImportData.historyImportDialog = true


    },
    //点击删除
    tableDel(competence, index, key) {
      console.log(competence)
      this.$confirm('此操作将删除该报价, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        switch (competence) {
          case 1:
            for (let i = 0; i < this.tableData.length; i++) {
              this.tableData[i].list.splice(key, 1);
            }
            this.supplierData.splice(key, 1);
            this.tableOperating.splice(key, 1);
            break
          case 2:
            this.tableData.splice(index, 1);
            break
          case 3:
            this.tableData.splice(index, 1);
            this.supplierData.splice(index, 1);
            break
        }

        this.$message({
          type: 'warning',
          message: '删除成功，需提交保存!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //权限1 总价的计算
    totalPrice(index, key, val) {
      if (val == 'discountUnitPrice') {
        //获取折后单价
        let discountUnitPrice = this.tableData[index].list[key].discountUnitPrice
        //获取数量
        let number = this.tableData[index].number;
        this.tableData[index].list[key].totalPrice = discountUnitPrice * number;
      }
      if (val == 'unitWeight') {
        //获取单件重量
        let discountUnitPrice = this.tableData[index].list[key].unitWeight
        //获取数量
        let number = this.tableData[index].number;
        this.tableData[index].list[key].grossWeight = discountUnitPrice * number;
      }
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
  },
};
</script>


<style lang="scss" scoped>
.table-input {
  li {
    margin: 8px 0;
  }
}
</style>
