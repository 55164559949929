<!--供应商-->
<template>
  <div class="inquiryIn-formation-form">
    <ul  v-for="(item,key) in supplierData" :key="key">
      <li>
        <span>供应商名称{{key+1}}</span>
        <el-input v-model="item.supplier_name" placeholder="请输入"></el-input>
      </li>
      <li>
        <span>联系人</span>
        <el-input v-model="item.contacts" placeholder="请输入"></el-input>
      </li>
      <li>
        <span>职务</span>
        <el-input v-model="item.job" placeholder="请输入"></el-input>
      </li>
      <li>
        <span>手机号</span>
        <el-input v-model="item.phone" placeholder="请输入"></el-input>
      </li>
      <li>
        <span>QQ/微信</span>
        <el-input v-model="item.wx" placeholder="请输入"></el-input>
      </li>
      <li>
        <span>邮箱</span>
        <el-input v-model="item.email" placeholder="请输入"></el-input>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['supplierData'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
