<template>
  <div class="inquiryIn-formation-form">
    <ul>
      <li>
        <span>询单编号</span>
        {{ inquiryDetailedTile.seek_no }}
      </li>
      <li>
        <span>订单状态</span>
        <i class="color-red">{{ inquiryDetailedTile.seek_status | filterState }}</i>
      </li>
      <template v-for="(item,key) in message">
        <li :key="key" v-if="$store.state.currentMessage.indexOf(key) !=-1">
          <span>{{ item.name }}</span>
          <i v-if="$store.state.currentPermissions!=2">{{ inquiryDetailedTile[item.field] }}</i>
          <!--不能输入-->
          <el-input
              v-model="inquiryDetailedTile[item.field]"
              placeholder="请输入内容"
              v-if="$store.state.currentPermissions==2 && item.disabled==1"
              disabled
          ></el-input>
          <el-input
              v-model="inquiryDetailedTile[item.field]"
              placeholder="请输入内容"
              v-if="$store.state.currentPermissions==2 && item.disabled!=1 && item.time!=1"
          ></el-input>
          <!--time-->
          <el-date-picker
              v-model="inquiryDetailedTile[item.field]"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-if="$store.state.currentPermissions==2 && item.time==1"
          >
          </el-date-picker>
        </li>
      </template>
      <li>

        <template v-if="$route.name=='inquiryList'">
          <template v-if="$store.state.currentPermissions==3">
            <span>采购员</span>
            <template v-for="(item,key) in buyer.buyer_list">
              <div :key="key" v-if="buyer.buyer_ids.indexOf(item.id)!==-1">
                <span>{{ item.name }}</span> 、
              </div>
            </template>
          </template>
          <template v-else>
            <!--采购员-->
            <inquiry-list-buyer
                :buyer="buyer"
                @buyerParentClose="buyerParentClose"
                v-if="$store.state.currentPermissions!=1"
            />
            <inquiry-list-buyer-one
                :buyer="buyer"
                @buyerParentClose="buyerParentClose"
                v-else
            />
            <el-button
                type="info"
                @click="$emit('editBuyer1Sub')"
                v-if="$store.state.currentPermissions==1 && $route.name=='inquiryList'"
            >确定修改采购员
            </el-button>
          </template>
        </template>

        <!--待报价列表-->
        <template v-if="$route.name=='pendingQuotationList'">
          <template v-if="$store.state.currentPermissions==2">
            <span>采购员</span>
            {{ order_buyer_for_onlyName }}
          </template>
          <template v-if="$store.state.currentPermissions==3">
            <span>采购员</span>
            <template v-for="(item,key) in buyer.buyer_list">
              <div :key="key" v-if="buyer.buyer_ids.indexOf(item.id)!==-1">
                <span>{{ item.name }}</span> 、
              </div>
            </template>
          </template>
        </template>

        <!--已报价列表-->
        <template v-if="$route.name=='quotedList'">
          <template v-if="$store.state.currentPermissions!=1">
            <span>采购员</span>
            {{ order_buyer_for_onlyName }}
          </template>
        </template>


      </li>
      <li v-if="inquiryDetailedTile.isHistoricalQuote">
        <span>历史报价日期</span>
        <el-date-picker
            v-model="inquiryDetailedTile.historicalQuoteDate"
            type="date"
            placeholder="选择日期"
            value-format="timestamp"
        >
        </el-date-picker>
      </li>
    </ul>
    <!--是否是历史订单-->
    <inquiry-list-purchase :inquiryDetailedTile="inquiryDetailedTile"/>
  </div>
</template>

<script>
// 是否是历史询单
import InquiryListPurchase from './InquiryListPurchase'
// 选择采购员 单选dialog
import InquiryListBuyerOne from './InquiryListBuyerOne'
import InquiryListBuyer from "./InquiryListBuyer";

let self = this;
export default {
  components: {
    InquiryListPurchase,
    InquiryListBuyerOne,
    InquiryListBuyer
  },
  props: [
    'inquiryDetailedTile',
    'buyer',
    'buyerSelectClose',
    'order_buyer_for_onlyName'
  ],
  data() {
    return {
      /*   0 created_at: '1',//创建时间
           1 end_time: '2',//截止时间
           2 customer_name: '3',//客户名称
           3 contacts: '4',//联系人
           4 job: '5',//职务
           5 phone: '6',//手机号
           6 saleman: '7',//销售员
           7 wx: '8',//QQ/微信
           8 email: '9',//邮箱
           9 offerDate: '12',//报价日期
           10 historicalQuoteDate: '13', //历史报价日期*/
      message: [{
        name: '创建时间',
        field: 'created_at',
        disabled: 1,
      }, {
        name: '截止时间',
        field: 'end_time',
        time: 1
      }, {
        name: '客户名称',
        field: 'customer_name',
      }, {
        name: '联系人',
        field: 'contacts',
      }, {
        name: '职务',
        field: 'job',
      }, {
        name: '手机号',
        field: 'phone',
      }, {
        name: '销售员',
        field: 'salemanName',
        disabled: 1,
      }, {
        name: 'QQ/微信',
        field: 'wx',
      }, {
        name: '邮箱',
        field: 'email',
      }, {
        name: '报价日期',
        field: 'offerDate',
      }, {
        name: '历史报价日期',
        field: 'historicalQuoteDate',
      }]
    };
  },
  mounted() {
  },
  created() {
    self = this;
  },
  filters: {
    filterState(state) {
      switch (state) {
        case 1:
          return '待询单'
        case 2:
          if (self.$store.state.currentPermissions == 2) {
            return '待报价'
          } else {
            return '待询单'
          }

        case 3:
          return '已报价'
      }
    }
  },
  methods: {
    //采购员  选择采购员关闭dialog还原数据
    buyerParentClose() {
      this.$emit('buyerSelectClose')
    },
  },
};
</script>

<style lang="scss" scoped></style>
