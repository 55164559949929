<!--批量操作按钮-->
<template>
  <div class="table-operating-button">
    <el-button type="primary" @click="addQuotation" v-if="$store.state.currentPermissions==1">增加报价</el-button>
    <el-button type="success" @click="excelTemplateDownload" v-if="$store.state.currentPermissions==1">表格模板下载
    </el-button>
    <common-excel-import
        v-if="$store.state.currentPermissions==1"
        :commonExcelImportProp="tableData"
        :supplierData="supplierData"
    />
  </div>
</template>

<script>
import CommonExcelImport from "@/components/CommonExcelImport";
import XLSX from 'xlsx'
import FileSaver from 'file-saver'

export default {
  components: {CommonExcelImport},
  props: ['supplierData', 'tableData', 'increaseQuotation', 'tableOperating'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    //表格模板下载
    excelTemplateDownload() {
      console.log(this.tableData)
      let wopts = {
        bookType: 'xlsx',
        bookSST: true,
        type: 'binary'
      };
      let workBook = {
        SheetNames: ['Sheet1'],
        Sheets: {},
        Props: {}
      };
      let table = [];
      for (let i = 0; i < this.tableData.length; i++) {
        let params = {
          "品牌（中文）": this.tableData[i].brand_ch,
          "品牌（英文）": this.tableData[i].brand_en,
         /* "品名":this.tableData[i].spec.split("/")[0],
          "规格型号":this.tableData[i].spec.split("/")[1],
          "单位":this.tableData[i].spec.split("/")[2],*/
          "品名/规格型号/单位": this.tableData[i].spec,
          "数量": this.tableData[i].number,
          "折后单价": '',
          "币种": '',
          "折扣": '',
          "总价": '',
          "单件重量": '',
          "重量单位": '',
          "总重量": '',
          "包装尺寸": '',
          "包装单位": '',
          "货期": '',
          "货期单位": ''
        }
        table[i] = params
      }
      workBook.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(table);
      FileSaver.saveAs(new Blob([this.changeData(XLSX.write(workBook, wopts))], {type: 'application/octet-stream'}), "采购表格导入模板.xlsx")
    },
    changeData(s) {
      if (typeof ArrayBuffer !== 'undefined') {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      } else {
        let buf = new Array(s.length);
        for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
    },
    // 点击增加报价
    addQuotation() {

      for (let i = 0; i < this.tableData.length; i++) {
        let tableData = {...this.increaseQuotation.tableData}
        this.tableData[i].list.push(tableData)
      }
      let supplierData = {...this.increaseQuotation.supplierData}
      this.supplierData.push(supplierData);

      //添加表格操作
      console.log()
      let tableOperating = [{
        name: '折后单价',//折后单价
        enName: 'discountUnitPrice',
        val: '',
        show: [1]
      }, {
        name: '币种',//币种
        enName: 'currency',
        val: '',
        show: [1],
        select: true,//是否是下拉
        selectOptions: [{
          value: '人民币',
          label: '人民币'
        },{
          value: '美元',
          label: '美元'
        }, {
          value: '欧元',
          label: '欧元'
        }, {
          value: '英镑',
          label: '英镑'
        }, {
          value: '港币',
          label: '港币'
        }, {
          value: '新加坡币',
          label: '新加坡币'
        }, {
          value: '澳大利亚元',
          label: '澳大利亚元'
        }, {
          value: '日元',
          label: '日元'
        }, {
          value: '迪拜币',
          label: '迪拜币'
        }, {
          value: '挪威币',
          label: '挪威币'
        }]
      }, {
        name: '折扣',//折扣
        enName: 'discount',
        val: '',
        show: [1]
      }, {
        name: '单件重量',//单件重量
        enName: 'unitWeight',
        val: '',
        show: [1]
      }, {
        name: '重量单位',//重量单位
        enName: 'unitOfWeight',
        val: '',
        show: [1],
        select: true,//是否是下拉
        selectOptions: [{
          value: 'kg',
          label: 'kg'
        }, {
          value: 'lbs',
          label: 'lbs'
        }]
      }, {
        name: '包装尺寸',//包装尺寸
        enName: 'packageDimensions',
        val: '',
        show: [1],
      }, {
        name: '包装单位',//包装单位
        enName: 'packingUnit',
        val: '',
        show: [1],
        select: true,//是否是下拉
        selectOptions: [{
          value: '立方厘米',
          label: '立方厘米'
        }, {
          value: '立方米',
          label: '立方米'
        }]
      }, {
        name: '货期',//货期
        enName: 'deliveryTime',
        val: '',
        show: [1]
      }, {
        name: '货期单位',//货期单位
        enName: 'deliveryUnit',
        val: '',
        show: [1],
        select: true,//是否是下拉
        selectOptions: [{
          value: '天',
          label: '天'
        }, {
          value: '周',
          label: '周'
        }, {
          value: '月',
          label: '月'
        }]
      }, {
        name: '汇率',//汇率
        enName: 'exchangeRate',
        val: '',
        show: [3]
      }, {
        name: '关税率',//关税率
        enName: 'tariffRate',
        val: '',
        show: [3]
      },
        {
          name: '增值税率',//增值税率
          enName: 'vatRate',
          val: '',
          show: [3]
        }, {
          name: '国际总运费',//国际总运费
          enName: 'internationalShipping',
          val: '',
          show: [3]
        }, {
          name: '银行总手续费',//银行总手续费
          enName: 'bankFees',
          val: '',
          show: [3]
        }, {
          name: '其他总费用',//其他总费用
          enName: 'otherFee',
          val: '',
          show: [3]
        }, {
          name: '国内运费',//国内总运费
          enName: 'domesticFreight',
          val: '',
          show: [3]
        }, {
          name: '毛利率',//毛利率
          enName: 'profit_rate',
          val: '',
          show: [3]
        },]
      this.tableOperating.push(tableOperating)
    }

  },
};
</script>

<style lang="scss" scoped>
.table-operating-button {
  @include flex();
  text-align: right;
  margin-right: 12px;
  margin-top: 12px;
}
</style>
