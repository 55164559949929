<template>
  <div>
    <div class="content-main-title">
      <span>成单信息</span>
      <div class="hr"></div>
    </div>
    <div class="inquiryIn-formation-form">
      <ul>
        <li>
          <span>记录日期</span>
          <el-date-picker
              v-model="orderMessage.recordDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </li>
        <li>
          <span>合同金额</span>
          <el-input v-model="orderMessage.contractAmount" placeholder="请输入"></el-input>
        </li>
        <li>
          <span>预付款比例</span>
          <el-input v-model="orderMessage.prepaymentRatio" placeholder="请输入"></el-input>
        </li>
        <li>
          <span>预收款金额</span>
          <el-input v-model="orderMessage.advancePaymentAmount1" placeholder="请输入"></el-input>
        </li>
        <li>
          <span>预付款金额</span>
          <el-input v-model="orderMessage.advancePaymentAmount2" placeholder="请输入"></el-input>
        </li>
        <li>
          <span>预收日期</span>
          <el-date-picker
              v-model="orderMessage.advanceReceiptDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </li>
        <li>
          <span>尾款金额</span>
          <el-input v-model="orderMessage.balanceAmount" placeholder="请输入"></el-input>
        </li>
        <li>
          <span>余款</span>
          <el-input v-model="orderMessage.balance" placeholder="请输入"></el-input>
        </li>
        <li>
          <span>待付款总额</span>
          <el-input v-model="orderMessage.totalPendingPayment" placeholder="请输入"></el-input>
        </li>
        <li>
          <span>描述</span>
          <el-input v-model="orderMessage.description" placeholder="请输入"></el-input>
        </li>
      </ul>
    </div>
    <!--成单附件-->
    <div class="content-main-title">
      <span>成单附件</span>
      <div class="hr"></div>
    </div>

    <inquiry-order-attachment :annex="orderMessage.annex"/>


  </div>
</template>

<script>
//成单附件
import inquiryOrderAttachment from './inquiryOrderAttachment'
export default {
  components: {
    inquiryOrderAttachment
  },
  props: ['orderMessage'],
  data() {
    return {
      recordDate:'',//记录日期
      contractAmount: '',//合同金额
      prepaymentRatio: '',//预付款比例
      advancePaymentAmount1: '',//预收款金额
      advancePaymentAmount2: '',//预付款金额
      advanceReceiptDate: '',//预收日期
      balanceAmount: '',//尾款金额
      balance: '',//余款
      totalPendingPayment: '',//待付款总额
      description: '',//描述
      annex: { //附件
        attachment: [{
          name: "111.ppt",
          url: "https://gc.hc-info.cn/storage/OFbwX4jqNJOul72lzr1EehPK94buXZAjC5szrH58.ppt"
        }],
        check: false // 是否销售可见
      }
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
