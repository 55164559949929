<!--询单截图-->
<template>
  <div class="screenshot-upload">
    <el-checkbox v-model="screenshot.check" class="mb-12" v-show="false">采购可见</el-checkbox>
    <!--
    action 上传接口地址
    show-file-list 是否显示已上传文件列表 缩略图
    list-type 文件列表的类型：文件上传后怎么布局
    on-preview 点击文件列表中已上传的文件时的钩子 screenshotPreview
    :before-remove 删除之前提示
    on-remove 文件列表移除文件时的钩子
    auto-upload 是否在选取文件后立即进行上传
    before-upload 上传文件之前的钩子  判断是否大于小于等
    on-error  上传失败
    on-success 上传成功
    multiple 是否支持多选文件
    limit 最多可以传多少个
    on-exceed 文件超出个数限制时的钩子
    name 上传的文件字段名
    data 上传时附带的额外参数
    -->
    <el-upload
        :action="screenshotAction"
        :show-file-list="false"
        list-type="picture-card"
        :auto-upload="true"
        :before-upload="screenshotUpload"
        :on-error="screenshotError"
        :on-success="screenshotSuccess"
        :multiple="false"
        :limit="9"
        :on-exceed="screenshotExceed"
        name="file"
        :data="screenshotData"
        v-if="$store.state.currentPermissions!=1"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <template v-if="$store.state.currentPermissions==1">
      <div class="content-main-img" v-if="screenshot.check">
        <ul>
          <li v-for="(item,key) in screenshot.img" :key="key">
            <img :src="item">
            <i class="el-icon-delete" @click="screenshotRemove(key)"></i>
          </li>
        </ul>
      </div>
    </template>
    <div class="content-main-img" v-else>
      <ul>
        <li v-for="(item,key) in screenshot.img" :key="key">
          <img :src="item">
          <i class="el-icon-delete" @click="screenshotRemove(key)"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['screenshot'],
  data() {
    return {
      // 截图
      screenshotAction: process.env.VUE_APP_URL + '/api/upload/uploadFile',
      screenshotData: {remarks: 1}, //上传添加的字段
    };
  },
  mounted() {
  },
  methods: {
    // //截图 放大缩小
    // screenshotPreview(file) {
    //   this.screenshotImg = file.url;
    //   this.screenshotDialog = true;
    // },
    //截图 移除文件时
    screenshotRemove(key) {
      this.$confirm('此操作将删除该文件, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.screenshot.img.splice(key, 1);
        this.$message({
          type: 'success',
          message: '删除成功,需要自行保存!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //截图 上传失败的钩子
    screenshotError() {
      this.$message.error('截图上传失败');
    },
    //截图 上传成功钩子
    screenshotSuccess(file) {
      this.screenshot.img.push(file.data)
    },
    //截图 上传文件之前的钩子
    screenshotUpload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //截图 文件超出个数限制时的钩子
    screenshotExceed(files, fileList) {
      this.$message.error(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
  }
  ,
}
;
</script>

<style lang="scss">
//截图上传
.screenshot-upload {
  .el-upload--picture-card, .el-upload-list__item {
    width: 120px;
    height: 120px;
    line-height: 120px;
  }

  .el-icon-check {
    position: absolute;
    left: 14px;
    top: 0px;
  }
}
</style>
