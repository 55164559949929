<template>
  <div>
    <div class="inquiry-list-box" v-loading="$store.state.loading">
      <el-scrollbar wrap-class="scrollbar-x">
        <div class="inquiry-list-box-left">
          <div class="search">
            <el-input
                placeholder="请输入角色名"
                v-model="keyword"
            >
              <i slot="suffix" class="el-icon-search" @click="leftData(undefined)"></i>
            </el-input>
            <!--   <el-input
                   placeholder="请输入采购员名称"
                   suffix-icon="el-icon-search"
                   v-model="keyword">
               </el-input>-->
          </div>
          <div class="list">
            <ul>
              <li v-for="(item,key) in inquirylist"
                  :key="key"
                  :class="{'list-active':rightlistActive==key}"
                  @click="rightlistClick(key,item.id,item.order_id,item.supervisorBuyer_id,item.uid)"
              >
                <dl>
                  <dt>
                    <div>
                      <i class="el-icon-caret-right"></i>
                      <span>{{ item.created_at }}</span>
                    </div>
                    <!--  <span
                          :class="{'n':item.seek_status==1 || item.seek_status==2,'n3':item.seek_status==3 || item.seek_status==4}"
                      >
                        {{ item.seek_status | filterState }}</span>-->
                  </dt>
                  <dd>询单号：{{ item.seek_no }}</dd>
                  <dd>品牌（中）:{{ item.brand_ch }}</dd>
                  <dd>品牌（英）:{{ item.brand_en }}</dd>

                  <dd v-if="$store.state.currentPermissions!=1">客户：{{ item.customer_name }}</dd>

                  <dd>销售员：{{item.salemanName}}</dd>
                  <dd v-if="$store.state.currentPermissions!=1">
                    <template v-if="$route.name!='quotedList'">
                      采购：{{ item.buyer_id }}
                    </template>
                    <template v-else>
                      采购：{{ item.leftOrder_buyer_for_onlyName }}
                    </template>
                  </dd>
                  <dd v-else-if="$store.state.manager==1">
                    <template>
                      采购：{{ item.buyer_id }}
                    </template>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
          <div class="leftCurPage">
            <el-pagination
                style="width: 50px"
                background
                layout=" pager"
                :page-size="30"
                @current-change="leftCurPageChange"
                :total="leftCurPage"
            >
            </el-pagination>
          </div>
        </div>
      </el-scrollbar>
      <!--right-->
      <el-scrollbar wrap-class="scrollbar-x" style="flex: 1">
        <div class="inquiry-list-box-right">
          <!--点击提交报价等按钮-->
          <inquiry-title-buttom
              :tableData="inquiryDetailedTile.tableData"
              @delButClick="delButClick"
              @sendQuoteClick="sendQuoteClick"
              @editButClick="editButClick"
              @subButClick="subButClick"
              @subFormClick="subFormClick"
              @exportMethod="exportMethod"
          />


          <div class="content-main">
            <!--询单信息-->
            <div class="content-main-title">
              <span>询单信息</span>
              <div class="hr"></div>
            </div>
            <inquiry-message
                :inquiryDetailedTile="inquiryDetailedTile"
                :buyer="buyer"
                :order_buyer_for_onlyName="order_buyer_for_onlyName"
                @buyerSelectClose="buyerSelectClose"
                @editBuyer1Sub="editBuyer1Sub"
            />
            <!--成单信息-->
            <inquiry-order-message
                :orderMessage="inquiryDetailedTile.orderMessage"
                v-if="this.$route.path=='/orderManagement/index'"
            />

            <!--询单截图-->
            <div class="content-main-title">
              <span>询单截图</span>
              <div class="hr"></div>
            </div>
            <inquiry-screenshot :screenshot="inquiryDetailedTile.screenshot"/>

            <div class="content-main-title">
              <span>询单备注</span>
              <div class="hr"></div>
            </div>
            <!--&& $route.name=='quotedList'-->
            <div v-if="$store.state.currentPermissions==2">
              <template v-if="$route.name=='quotedList'">
                <div style="white-space: pre-line;">{{ desc_saleman }}</div>
              </template>
              <template v-else>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2}"
                    placeholder="请输入内容"
                    v-model="desc_saleman">
                </el-input>
              </template>

            </div>
            <div v-else style="white-space: pre-line;">{{ desc_saleman }}</div>

            <!--询单附件-->
            <div class="content-main-title">
              <span>询单附件</span>
              <div class="hr"></div>
            </div>
            <inquiry-attachment :attachment="inquiryDetailedTile.attachment"/>

            <!--询单明细列表-->
            <div class="content-main-title">
              <span>询单明细列表</span>
              <div class="hr"></div>
            </div>
            <!--表格操作-->
            <inquiry-table-operating
                :tableOperating="tableOperating"
                :tableData="inquiryDetailedTile.tableData"
                v-if="$store.state.currentPermissions!=2"
            />
            <!--表格按钮-->
            <inquiry-table-operating-button
                :supplierData="inquiryDetailedTile.supplierData"
                :tableData="inquiryDetailedTile.tableData"
                :increaseQuotation="increaseQuotation"
                :tableOperating="tableOperating"
                v-if="$store.state.currentPermissions!=2"
            />
            <!--表格数据-->
            <InquiryTable
                :tableBuyerId="tableBuyerId"
                :tableData="inquiryDetailedTile.tableData"
                :tableBuyer="tableBuyer"
                :supplierData="inquiryDetailedTile.supplierData"
                :tableOperating="tableOperating"
                :historyImportData="historyImportData"
                :singleSelectionProp="singleSelectionProp"
                @tableBuyerIdChange="tableBuyerIdChange"
                @tableBuyerSelectChange="tableBuyerSelectChange"
                @historyImportMethods="historyImportMethods"
            />
            <!--v-if="$store.state.currentPermissions!=2"-->
            <template>
              <div class="content-main-title">
                <span>业务备注</span>
                <div class="hr"></div>
              </div>

              <template v-if="$store.state.currentPermissions!=3">
                <div style="white-space: pre-line;">{{ desc_center }}</div>
              </template>

              <template v-else>
                <div>
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 2}"
                      placeholder="请输入内容"
                      v-model="desc_center">
                  </el-input>
                </div>
              </template>

            </template>


            <!--报价截图-->
            <template v-if="$store.state.currentPermissions!=2">
              <div class="content-main-title">
                <span>报价截图</span>
                <div class="hr"></div>
              </div>
              <inquiry-quote-screenshot
                  :quoteScreenshot="inquiryDetailedTile.quoteScreenshot"
              />
            </template>
            <template v-if="$store.state.currentPermissions==2 && $route.name=='quotedList'">
              <div class="content-main-title">
                <span>报价截图</span>
                <div class="hr"></div>
              </div>
              <inquiry-quote-screenshot
                  :quoteScreenshot="inquiryDetailedTile.quoteScreenshot"
              />
            </template>

            <!--报价备注-->
            <template v-if="$store.state.currentPermissions!=2">
              <div class="content-main-title">
                <span>报价备注</span>
                <div class="hr"></div>
              </div>
              <div>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2}"
                    placeholder="请输入内容"
                    v-model="desc_buyer">
                </el-input>
              </div>
            </template>

            <template v-if="$store.state.currentPermissions==2 && $route.name=='quotedList'">
              <div class="content-main-title">
                <span>报价备注</span>
                <div class="hr"></div>
              </div>
              <div v-if="$store.state.currentPermissions!=2">
                <!--&& $route.name=='inquiryList'-->
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2}"
                    placeholder="请输入内容"
                    v-model="desc_buyer">
                </el-input>
              </div>
              <div v-else style="white-space: pre-line;">{{ desc_buyer }}</div>
            </template>


            <!--报价附件-->
            <template v-if="$store.state.currentPermissions!=2">
              <div class="content-main-title">
                <span>报价附件</span>
                <div class="hr"></div>
              </div>
              <inquiry-quotation-attachment
                  :quotationAttachment="inquiryDetailedTile.quotationAttachment"
              />
            </template>
            <template v-if="$store.state.currentPermissions==2 && $route.name=='quotedList'">
              <div class="content-main-title">
                <span>报价附件</span>
                <div class="hr"></div>
              </div>
              <inquiry-quotation-attachment
                  :quotationAttachment="inquiryDetailedTile.quotationAttachment"
              />
            </template>

            <!--供应商-->
            <div class="content-main-title" v-if="$store.state.currentPermissions!=2">
              <span>供应商</span>
              <div class="hr"></div>
            </div>
            <inquiry-supplier
                :supplierData="inquiryDetailedTile.supplierData"
                v-if="$store.state.currentPermissions!=2"
            />

            <!--订单跟着记录-->
            <inquiry-order-record
                :orderRecord="inquiryDetailedTile.orderRecord"
                v-if="this.$route.path=='/orderManagement/index'"
            />
          </div>
        </div>
      </el-scrollbar>
    </div>
    <!--历史导入dialog-->
    <inquiry-list-dialog
        :tableData="inquiryDetailedTile.tableData"
        :historyImportData="historyImportData"
        @historyImportMethods="historyImportMethods"
    />
    <!--确定成单dialog-->
    <inquiry-sub-form-a-single :subFormASingleData="subFormASingleData" @subFormDialogClick="subFormDialogClick"/>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
//点击提交报价等按钮
import InquiryTitleButtom from './InquiryTitleButtom'
// 询单信息
import InquiryMessage from './InquiryMessage'
// 成单信息
import InquiryOrderMessage from './InquiryOrderMessage'
// 询单截图
import InquiryScreenshot from './InquiryScreenshot'
// 询单附件
import InquiryAttachment from './InquiryAttachment'
// 表格操作
import InquiryTableOperating from './InquiryTableOperating'
// 表格按钮
import InquiryTableOperatingButton from './InquiryTableOperatingButton'
// 表格数据
import InquiryTable from './InquiryTable'
// 历史导入
import InquiryListDialog from './InquiryListDialog'
//报价截图
import InquiryQuoteScreenshot from './InquiryQuoteScreenshot'
//报价附件
import InquiryQuotationAttachment from './InquiryQuotationAttachment'
//供应商
import InquirySupplier from './InquirySupplier'
//订单记录
import InquiryOrderRecord from './InquiryOrderRecord'
//确定成单
import InquirySubFormASingle from './InquirySubFormASingle'
import {
  leftNewOrder,
  rightNewOrder,
  delNewOrder,
  offerWholePut,
  newOrderPost,
  newOrderPut,
  newOrderPost2,
  offerWholePost,
  newOrderPost1,
  historyImport1Get,
  changeOrderBuyer
} from '@/api/module/inquiryList'
//
import {currentListData} from "@/api/module/basic";

let self = this;
export default {
  components: {
    InquiryTitleButtom,
    InquiryMessage,
    InquiryOrderMessage,
    InquiryTable,
    InquiryScreenshot,
    InquiryAttachment,
    InquiryTableOperating,
    InquiryTableOperatingButton,
    InquiryListDialog,
    InquiryQuoteScreenshot,
    InquiryQuotationAttachment,
    InquirySupplier,
    InquiryOrderRecord,
    InquirySubFormASingle
  },
  props: [],
  data() {
    return {
      leftCurPage:0,//let边总数
      leftCurrent_page:1,//left 左边第几页
      buyer_id_1: null,
      supervisorBuyer_id: '',//采购主管获取的采购员id
      order_buyer_for_onlyName: '',//右边采购员
      //成单选择了几个
      singleSelectionProp: {
        ids: []
      },
      // 权限3  才有用
      select_sort: 1,
      desc_saleman: '',//销售备注
      desc_buyer: '',//采购备注
      desc_center: '',//成本中心备注
      keyword: '',//搜索
      //权限2 确定成单
      subFormASingleData: {
        dialogShow: false,
        contract_money: '',// 合同金额
        advance_money: '',//预收款
        advance_date: new Date(),//预收款日期
        deliver_date: new Date(),//预计发货日期
        balance: '',//余款
        finish_desc: '',//描述
        finish_attachment: [],//附件
      },

      //左边数据
      rightlistActive: 0,
      leftPage: 1,
      leftTotal: 1,
      inquirylist: [
        {
          created_at: '',//创建时间
          seek_status: 1,//询单状态
          seek_no: '',//询单号
          brand_ch: '',//品牌（中文）
          brand_en: '', //品牌（英文）
          customer_name: '',//客户名称
          buyer_id: '',//采购员
          leftOrder_buyer_for_onlyName: ''//报价列表采购员
        }
      ],

      // 表格操作
      tableOperating: [
        [{
          name: '折后单价',//折后单价
          enName: 'discountUnitPrice',
          val: '',
          show: [1]
        }, {
          name: '币种',//币种
          enName: 'currency',
          val: '',
          show: [1],
          select: true,//是否是下拉
          selectOptions: [{
            value: '人民币',
            label: '人民币'
          },{
            value: '美元',
            label: '美元'
          }, {
            value: '欧元',
            label: '欧元'
          }, {
            value: '英镑',
            label: '英镑'
          }, {
            value: '港币',
            label: '港币'
          }, {
            value: '新加坡币',
            label: '新加坡币'
          }, {
            value: '澳大利亚元',
            label: '澳大利亚元'
          }, {
            value: '日元',
            label: '日元'
          }, {
            value: '迪拜币',
            label: '迪拜币'
          }, {
            value: '挪威币',
            label: '挪威币'
          }]
        }, {
          name: '折扣',//折扣
          enName: 'discount',
          val: '',
          show: [1]
        }, {
          name: '单件重量',//单件重量
          enName: 'unitWeight',
          val: '',
          show: [1]
        }, {
          name: '重量单位',//重量单位
          enName: 'unitOfWeight',
          val: '',
          show: [1],
          select: true,//是否是下拉
          selectOptions: [{
            value: 'kg',
            label: 'kg'
          }, {
            value: 'lbs',
            label: 'lbs'
          }]
        }, {
          name: '包装尺寸',//包装尺寸
          enName: 'packageDimensions',
          val: '',
          show: [1],
        }, {
          name: '包装单位',//包装单位
          enName: 'packingUnit',
          val: '',
          show: [1],
          select: true,//是否是下拉
          selectOptions: [{
            value: '立方厘米',
            label: '立方厘米'
          }, {
            value: '立方米',
            label: '立方米'
          }]
        }, {
          name: '货期',//货期
          enName: 'deliveryTime',
          val: '',
          show: [1]
        }, {
          name: '货期单位',//货期单位
          enName: 'deliveryUnit',
          val: '',
          show: [1],
          select: true,//是否是下拉
          selectOptions: [{
            value: '天',
            label: '天'
          }, {
            value: '周',
            label: '周'
          }, {
            value: '月',
            label: '月'
          }]
        }, {
          name: '汇率',//汇率
          enName: 'exchangeRate',
          val: '',
          show: [3]
        }, {
          name: '关税率',//关税率
          enName: 'tariffRate',
          val: '',
          show: [3]
        },
          {
            name: '增值税率',//增值税率
            enName: 'vatRate',
            val: '',
            show: [3]
          }, {
          name: '国际总运费',//国际总运费
          enName: 'internationalShipping',
          val: '',
          show: [3]
        }, {
          name: '银行总手续费',//银行总手续费
          enName: 'bankFees',
          val: '',
          show: [3]
        }, {
          name: '其他总费用',//其他总费用
          enName: 'otherFee',
          val: '',
          show: [3]
        }, {
          name: '国内运费',//国内总运费
          enName: 'domesticFreight',
          val: '',
          show: [3]
        }, {
          name: '毛利率',//毛利率
          enName: 'profit_rate',
          val: '',
          show: [3]
        },]
      ],

      // 采购员 dialog
      buyer: {
        username: '',//搜索
        buyerSelectDialog: false, //选择采购员dialog
        buyer_ids: [1],//采购员
        //接口返回的数据 采购员列表
        buyer_list: [
          /*{
            name: 'caicai1',
            id: 1
          }*/
        ],
      },

      //右边数据
      //表格 采购员 权限3要用下拉后拿最新的值
      tableBuyerId: null,//选择采购员id 权限3才要的  表格下拉的
      tableBuyer: [
        /*  {
            id: 11,
            name: 'caicai1',
          }*/
      ],
      inquiryDetailedTile: {
        id: '',
        seek_no: '',//询单编号
        seek_status: 1,//询单状态 1-待询单 2-待报价 3-已报价
        created_at: '',//创建时间
        end_time: '',//截止时间
        customer_name: '',//客户名称
        contacts: '',//联系人
        job: '',//职务
        phone: '',//手机号
        saleman: '',//销售员
        salemanName: '',//销售员名称
        wx: '',//QQ/微信
        email: '',//邮箱
        offerDate: '',//报价日期
        isHistoricalQuote: false,// 是否是历史询单
        historicalQuoteDate: '', //历史报价日期
        // 报价截图 附件全部
        quoteAllData: [],
        //供应商 全部信息
        supplierAllData: [],
        //成单信息
        orderMessage: {
          recordDate: '',//记录日期
          contractAmount: '',//合同金额
          prepaymentRatio: '',//预付款比例
          advancePaymentAmount1: '',//预收款金额
          advancePaymentAmount2: '',//预付款金额
          advanceReceiptDate: '',//预收日期
          balanceAmount: '',//尾款金额
          balance: '',//余款
          totalPendingPayment: '',//待付款总额
          description: '',//描述
          annex: { //附件
            attachment: [
              /*  {
                  name: "111.ppt",
                  url: "https://gc.hc-info.cn/storage/OFbwX4jqNJOul72lzr1EehPK94buXZAjC5szrH58.ppt"
                }*/
            ],
            check: false // 是否销售可见
          }
        },
        //订单跟踪记录
        orderRecord: [{
          operate: '',//操作
          recordDate: '',//记录日期
          operator: '',//操作人
          description: '',//描述
          annex: [
            /*{//附件
              name: "111.ppt",
              url: "https://gc.hc-info.cn/storage/OFbwX4jqNJOul72lzr1EehPK94buXZAjC5szrH58.ppt"
            }*/
          ],
        }],
        //询单截图
        screenshot: {
          img: [
            /*         "https://gc.hc-info.cn/storage/JqV4Ss1P0ggz12iazSIRvNLb3FsPb6mv1w8q7XL1.jpeg",
                     "https://gc.hc-info.cn/storage/Qdb1tFTWWjpcHkqw3GZ3iHkA1QpRBJMyquUchCvN.jpeg"*/
          ],// 上传截图图片
          check: true // 是否销售可见按钮
        },
        //询单附件
        attachment: {
          /*  attachment: [{
              name: "111.ppt",
              url: "https://gc.hc-info.cn/storage/OFbwX4jqNJOul72lzr1EehPK94buXZAjC5szrH58.ppt"
            }, {
              name: "112112.xls",
              url: "https://gc.hc-info.cn/storage/GzM7wQYDANoKMHlcqIuXc4gR93BxHeOu2mVlAZrj.xls"
            }],
            check: false // 是否销售可见*/
        },
        quoteScreenshotId: '',//选择报价id
        //报价截图
        quoteScreenshot: {
          img: [],// 上传截图图片
          check: true // 是否销售可见
        },
        //报价附件
        quotationAttachment: {
          attachment: [],// 上传附件
          check: true // 是否销售可见
        },
        //  表格数据
        tableData: [{
          brand_ch: '', //品牌（中文）
          brand_en: '', //品牌（英文）
          spec: '', //品名规格型号
          number: '', //数量
          // buyerId: '',//采购员id 权限3才要的 已改
          tableBuyerSelect: 0, //选完采购员选择了第几个报价 这个不要传给后台

          exchangeRate: '',//汇率
          convertedUnitPrice: '',//换算单价
          internationalShipping: '',//国际运费
          bankFees: '',//银行手续费
          tariff: '',//关税
          vat: '',//增值税
          otherFee: '',//其他费用
          domesticFreight: '',//国内运费
          grossProfit: '',//毛利
          profit_rate: '',//毛利率
          unitPrice: '',//销售单价
          totalSalePrice: '',//销售总价

          list: [{
            //采购端才有 权限1
            discountUnitPrice: '',//折后单价
            currency: '',//币种
            discount: '',//折扣
            totalPrice: '',//总价
            unitWeight: '',//单件重量
            unitOfWeight: '',//重量单位
            grossWeight: '',//总重量
            packageDimensions: '',//包装尺寸
            packingUnit: '',//包装单位
            deliveryTime: '',//货期
            deliveryUnit: '',//货期单位
          }],
        }],
        // 供应商数据  权限三下拉后要去接口拿数据
        supplierData: [
          /*  {
              supplier_name: '1', // 供应商名称
              contacts: '2',// 联系人
              job: '3', // 职务
              phone: '4',// 手机号
              wx: '5',// QQ/微信
              email: '6666',// 邮箱
              sort:1,//批次
            }*/
        ]
      },
      // 点击增加报价 插入的数据 采购端 权限1
      increaseQuotation: {
        tableData: {
          discountUnitPrice: '',//折后单价
          currency: '',//币种
          discount: '',//折扣
          totalPrice: '',//总价
          unitWeight: '',//单件重量
          unitOfWeight: '',//重量单位
          grossWeight: '',//总重量
          packageDimensions: '',//包装尺寸
          packingUnit: '',//包装单位
          deliveryTime: '',//货期
          deliveryUnit: '',//货期单位
        },
        supplierData: {
          supplier_name: '', // 供应商名称
          contacts: '',// 联系人
          job: '', // 职务
          phone: '',// 手机号
          wx: '',// QQ/微信
          email: '',// 邮箱
          sort: '',//批次
        }
      },
      // 历史导入数据
      historyImportData: {
        search: '', // 搜索
        historyImportDialog: false,
        selectData: null,//单选历史导入第几个
        index: '',//点击历史按钮要用 当前行
        key: '',//点击历史按钮要用 当前行中的当前行
        current_page: 1,
        total: 123,
        params: {
          brand_ch: '',
          brand_en: '',
          spec: '',
        },
        tableData: [{
          indexId: 0, //当前索引

          brand_ch: '', //品牌（中文）
          brand_en: '', //品牌（英文）
          spec: '',//品名规格型号
          discountUnitPrice: '',//折后单价
          currency: '',//币种
          discount: '',//折扣
          unitWeight: '',//单件重量
          unitOfWeight: '',//重量单位
          packageDimensions: '',//包装尺寸
          packingUnit: '',//包装位
          deliveryTime: '',//货期单
          deliveryUnit: '',//货期单位
          totalPrice: '',//总价
          grossWeight: '',//总重量
          offerDate: '',//报价日期
        }]
      }
    };
  },
  filters: {
    filterState(state) {
      switch (state) {
        case 1:
          return '待询单'
        case 2:
          if (self.$store.state.currentPermissions == 2) {
            return '待询单'
          } else {
            return '待报价'
          }
        case 3:
          return '已报价'
        case 4:
          return '已完成'
      }
    }
  },
  created() {
    this.roleList();
    this.leftData();
    self = this;
  },
  mounted() {

    // $router.name='inquiryList'
  },
  methods: {
    //left 分页
    leftCurPageChange(val){
      this.leftCurrent_page=val;
      this.leftData();
    },
    //采购端修改采购员
    editBuyer1Sub() {
      let data = {
        order_id: this.inquiryDetailedTile.id,
        buyer_id_1: this.buyer_id_1[0],
        buyer_id_2: this.buyer.buyer_ids[0]
      }
      changeOrderBuyer(data).then(() => {
        this.$message.success('修改成功');
        this.$router.go(0);
      })
      // this.buyer_id_1= {...this.buyer.buyer_ids}
    },
    //历史导入
    historyImportMethods() {
      let params = {
        page: this.historyImportData.current_page,
        brand_ch: this.historyImportData.params.brand_ch,
        brand_en: this.historyImportData.params.brand_en,
        spec: this.historyImportData.params.spec,
      }
      historyImport1Get(params).then((result) => {
        this.historyImportData.total = result.data.total;
        let res = result.data.data;
        this.historyImportData.tableData = res.map(item => ({
          brand_ch: item.brand_ch, //品牌（中文）
          brand_en: item.brand_en, //品牌（英文）
          spec: item.spec,//品名规格型号
          discountUnitPrice: item.purchase_money,//折后单价
          currency: item.currency,//币种
          discount: item.discount,//折扣
          unitWeight: item.weight,//单件重量
          unitOfWeight: item.weight_unit,//重量单位
          packageDimensions: item.pack_size,//包装尺寸
          packingUnit: item.pack_unit,//包装位
          deliveryTime: item.delivery_date,//货期单
          deliveryUnit: item.delivery_date_unit,//货期单位
          totalPrice: item.total_money,//总价
          grossWeight: item.weight_total,//总重量
          offerDate: this.Utils.timeDate(item.created_at),//报价日期
        }));


        console.log(result);
      })
    },
    //title导出按钮
    exportMethod() {

      /* //  表格数据
       tableData: [{
          brand_ch: '', //品牌（中文）
          brand_en: '', //品牌（英文）
          spec: '', //品名规格型号
          number: '', //数量
          // buyerId: '',//采购员id 权限3才要的 已改
          tableBuyerSelect: 0, //选完采购员选择了第几个报价 这个不要传给后台

          exchangeRate: '',//汇率
          convertedUnitPrice: '',//换算单价
          internationalShipping: '',//国际运费
          bankFees: '',//银行手续费
          tariff: '',//关税
          vat: '',//增值税
          otherFee: '',//其他费用
          domesticFreight: '',//国内运费
          grossProfit: '',//毛利
          profit_rate: '',//毛利率
          unitPrice: '',//销售单价
          totalSalePrice: '',//销售总价

          list: [{
            //采购端才有 权限1
            discountUnitPrice: '',//折扣单价
            currency: '',//币种
            discount: '',//折扣
            totalPrice: '',//总价
            unitWeight: '',//单件重量
            unitOfWeight: '',//重量单位
            grossWeight: '',//总重量
            packageDimensions: '',//包装尺寸
            packingUnit: '',//包装单位
            deliveryTime: '',//货期
            deliveryUnit: '',//货期单位
          }],
        }],*/
      let wopts = {
        bookType: 'xlsx',
        bookSST: true,
        type: 'binary'
      };
      let workBook = {
        SheetNames: ['Sheet1'],
        Sheets: {},
        Props: {}
      };
      let table = [];
      //成本中心导出
      if (this.$store.state.currentPermissions == 3) {
        // this.tableBuyerId 选择的采购员id
        // this.tableBuyer 采购员集合
        let buyerName = '';
        for (let i = 0; i < this.tableBuyer.length; i++) {
          if (this.tableBuyer[i].id == this.tableBuyerId) {
            buyerName = this.tableBuyer[i].name;
          }
        }
        console.log(this.inquiryDetailedTile.tableData)
        for (let i = 0; i < this.inquiryDetailedTile.tableData.length; i++) {
          let params = {
            "品牌（中文）": this.inquiryDetailedTile.tableData[i].brand_ch,
            "品牌（英文）": this.inquiryDetailedTile.tableData[i].brand_en,
            "品名/规格型号/单位": this.inquiryDetailedTile.tableData[i].spec,
            "数量": this.inquiryDetailedTile.tableData[i].number,
            "采购员": buyerName,
            "折后单价": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].discountUnitPrice,
            "币种": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].currency,
            "折扣": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].discount,
            "总价": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].totalPrice,
            "单件重量": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].unitWeight,
            "重量单位": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].unitOfWeight,
            "总重量": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].grossWeight,
            "包装尺寸": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].packageDimensions,
            "包装单位": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].packingUnit,
            "货期": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].deliveryTime,
            "货期单位": this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].deliveryUnit,
            "汇率": this.inquiryDetailedTile.tableData[i].exchangeRate,
            "换算单价": this.inquiryDetailedTile.tableData[i].convertedUnitPrice,
            "国际运费": this.inquiryDetailedTile.tableData[i].internationalShipping,
            "银行手续费": this.inquiryDetailedTile.tableData[i].bankFees,
            "关税": this.inquiryDetailedTile.tableData[i].tariff,
            "增值税": this.inquiryDetailedTile.tableData[i].vat,
            "其他费用": this.inquiryDetailedTile.tableData[i].otherFee,
            "国内运费": this.inquiryDetailedTile.tableData[i].domesticFreight,
            "毛利": this.inquiryDetailedTile.tableData[i].grossProfit,
            "毛利率": this.inquiryDetailedTile.tableData[i].profit_rate,
            "销售单价": this.inquiryDetailedTile.tableData[i].unitPrice,
            "销售总价": this.inquiryDetailedTile.tableData[i].totalSalePrice,
          }
          table[i] = params
        }
      }
      //采购端导出的数据
      if (this.$store.state.currentPermissions == 1) {
        for (let i = 0; i < this.inquiryDetailedTile.tableData.length; i++) {
          for (let j = 0; j < this.inquiryDetailedTile.tableData[i].list.length; j++) {
            let params = {
              "品牌（中文）": this.inquiryDetailedTile.tableData[i].brand_ch,
              "品牌（英文）": this.inquiryDetailedTile.tableData[i].brand_en,
              "品名/规格型号/单位": this.inquiryDetailedTile.tableData[i].spec,
              "数量": this.inquiryDetailedTile.tableData[i].number,
              "折后单价": this.inquiryDetailedTile.tableData[i].list[j].discountUnitPrice,//折后单价
              "币种": this.inquiryDetailedTile.tableData[i].list[j].currency,//币种
              "折扣": this.inquiryDetailedTile.tableData[i].list[j].discount,//折扣
              "总价": this.inquiryDetailedTile.tableData[i].list[j].totalPrice,//总价
              "单件重量": this.inquiryDetailedTile.tableData[i].list[j].unitWeight,//单件重量
              "重量单位": this.inquiryDetailedTile.tableData[i].list[j].unitOfWeight,//重量单位
              "总重量": this.inquiryDetailedTile.tableData[i].list[j].grossWeight,//总重量
              "包装尺寸": this.inquiryDetailedTile.tableData[i].list[j].packageDimensions,//包装尺寸
              "包装单位": this.inquiryDetailedTile.tableData[i].list[j].packingUnit,//包装单位
              "货期": this.inquiryDetailedTile.tableData[i].list[j].deliveryTime,//货期
              "货期单位": this.inquiryDetailedTile.tableData[i].list[j].deliveryUnit,//货期单位
            }
            table.push(params)
          }
        }
      }
      //销售端导出的数据
      if (this.$store.state.currentPermissions == 2) {
        for (let i = 0; i < this.inquiryDetailedTile.tableData.length; i++) {
          let params = {
            "品牌（中文）": this.inquiryDetailedTile.tableData[i].brand_ch,
            "品牌（英文）": this.inquiryDetailedTile.tableData[i].brand_en,
            "品名/规格型号/单位": this.inquiryDetailedTile.tableData[i].spec,
            "数量": this.inquiryDetailedTile.tableData[i].number,
            "销售单价": this.inquiryDetailedTile.tableData[i].unitPrice,
            "销售总价": this.inquiryDetailedTile.tableData[i].totalSalePrice,
            "货期": this.inquiryDetailedTile.tableData[i].list[0].deliveryTime,
            "货期单位": this.inquiryDetailedTile.tableData[i].list[0].deliveryUnit
          }
          table[i] = params
        }
      }
      //1、XLSX.utils.json_to_sheet(data) 接收一个对象数组并返回一个基于对象关键字自动生成的“标题”的工作表，默认的列顺序由使用Object.keys的字段的第一次出现确定
      //2、将数据放入对象workBook的Sheets中等待输出
      workBook.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(table);

      //3、XLSX.write() 开始编写Excel表格
      //4、changeData() 将数据处理成需要输出的格式
      FileSaver.saveAs(new Blob([this.changeData(XLSX.write(workBook, wopts))], {type: 'application/octet-stream'}), "导出数据.xlsx")
    },
    changeData(s) {

      //如果存在ArrayBuffer对象(es6) 最好采用该对象
      if (typeof ArrayBuffer !== 'undefined') {

        //1、创建一个字节长度为s.length的内存区域
        let buf = new ArrayBuffer(s.length);

        //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾
        let view = new Uint8Array(buf);

        //3、返回指定位置的字符的Unicode编码
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;

      } else {
        let buf = new Array(s.length);
        for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
    },
    //获取采购员
    roleList(name) {
      let params = {
        user_type: 1,
        lock_status: 1,
        username: name
      }
      currentListData(params).then((result) => {
        this.buyer.buyer_list = result.data.data.map(item => ({
          name: item.username,
          id: item.id
        }));

      })
    },

    //权限2点击修改按钮
    editButClick() {

      if (this.inquiryDetailedTile.customer_name == '') {
        this.$message.error('客户名称不能为空');
        return false;
      }
     /* if (this.inquiryDetailedTile.end_time == '') {
        this.$message.error('截止时间不能为空');
        return false;
      }*/
      if (this.inquiryDetailedTile.saleman_id == '') {
        this.$message.error('销售员不能为空');
        return false;
      }
      if (this.buyer.buyer_ids.length == 0) {
        this.$message.error('采购员不能为空');
        return false;
      }
      if (this.inquiryDetailedTile.customer_name == '') {
        this.$message.error('客户名称不能为空');
        return false;
      }
      if (this.inquiryDetailedTile.contacts == '') {
        this.$message.error('联系人不能为空');
        return false;
      }
      if (this.inquiryDetailedTile.job == '') {
        this.$message.error('职务不能为空');
        return false;
      }
     /* if (this.inquiryDetailedTile.phone == '') {
        this.$message.error('手机号不能为空');
        return false;
      }
      if (this.inquiryDetailedTile.wx == '') {
        this.$message.error('QQ/微信不能为空');
        return false;
      }*/
      if (this.inquiryDetailedTile.email == '') {
        this.$message.error('邮箱不能为空');
        return false;
      }
      if (this.inquiryDetailedTile.tableData == '') {
        this.$message.error('询单明细列表不能为空');
        return false;
      }

      let inquiryDetailedTile = this.inquiryDetailedTile;
      let seek_list = [];
      seek_list = this.inquiryDetailedTile.tableData.map(item => ({
        brand_ch: item.brand_ch,
        brand_en: item.brand_en,
        spec: item.spec,
        number: item.number
      }));
      //  表格数据
      let data = {
        order_id: inquiryDetailedTile.id,//询单id
        end_time: this.Utils.stampData(inquiryDetailedTile.end_time),//  截止时间
        saleman_id: inquiryDetailedTile.saleman,//  销售员
        customer_name: inquiryDetailedTile.customer_name,//  客户名称
        contacts: inquiryDetailedTile.contacts,//  联系人
        job: inquiryDetailedTile.job, //  职务
        phone: inquiryDetailedTile.phone,//手机号码
        wx: inquiryDetailedTile.wx,// QQ 微信
        email: inquiryDetailedTile.email,//邮箱
        screenshot: inquiryDetailedTile.screenshot.img.toString(),//询单截图
        screenshot_status: inquiryDetailedTile.screenshot.check ? 1 : 2, //询单截图-采购  1-可见 2-不可见
        attachment: JSON.stringify(inquiryDetailedTile.attachment.attachment),//询单附件
        attachment_status: inquiryDetailedTile.attachment.check ? 1 : 2,//  询单附件-采购 1-可见 2-不可见
        brand_ch: inquiryDetailedTile.tableData[0].brand_ch,//  品牌（中文）
        brand_en: inquiryDetailedTile.tableData[0].brand_en,//  品牌（英文）
        buyer_ids: JSON.stringify(this.buyer.buyer_ids),//采购员
        seek_list: JSON.stringify(seek_list),//询单明细列表
        desc_saleman: this.desc_saleman
      }
      newOrderPost(data).then(() => {
        this.$message.success('修改成功');
        this.$router.go(0)
      })

    },
    //权限2 确定成单
    subFormClick() {
      if (this.singleSelectionProp.ids.length == 0) {
        this.$message.error('请勾选要成单商品');
        return false;
      }
      this.subFormASingleData.contract_money = 0;
      for (let i = 0; i < this.inquiryDetailedTile.tableData.length; i++) {
        if (this.singleSelectionProp.ids.indexOf(this.inquiryDetailedTile.tableData[i].id) != -1) {
          this.subFormASingleData.contract_money = Number(this.subFormASingleData.contract_money) + Number(this.inquiryDetailedTile.tableData[i].totalSalePrice)
        }
      }

      // subFormASingleData.contract_money
      this.subFormASingleData.dialogShow = true;

    },
    //权限2 确定成单dialog确定
    subFormDialogClick() {
      let subFormASingleData = this.subFormASingleData;
      let center_offer_list = [];

      for (let i = 0; i < this.inquiryDetailedTile.tableData.length; i++) {
        let center_offer_list_data = {
          id: this.inquiryDetailedTile.id,
          sale_money: this.inquiryDetailedTile.tableData[i].unitPrice,//单价
          sale_money_total: this.inquiryDetailedTile.tableData[i].totalSalePrice,//总价
          deliveryTime: this.inquiryDetailedTile.tableData[i].list[0].delivery_date,//货期
          deliveryUnit: this.inquiryDetailedTile.tableData[i].list[0].delivery_date_unit,//货期单位
        }
        center_offer_list.push(center_offer_list_data)
      }

      let data = {
        order_id: this.inquiryDetailedTile.id,//询单id
        contract_money: subFormASingleData.contract_money,// 合同金额
        advance_money: subFormASingleData.advance_money,//预收款
        advance_date: subFormASingleData.advance_date / 1000,//预收款日期
        deliver_date: subFormASingleData.deliver_date / 1000,//预计发货日期
        balance: subFormASingleData.balance,//余款
        finish_desc: subFormASingleData.finish_desc,//描述
        finish_attachment: JSON.stringify(subFormASingleData.finish_attachment),//附件
        center_offer_list: JSON.stringify(center_offer_list),
        checked: JSON.stringify(this.singleSelectionProp.ids),
      }
      newOrderPut(data).then(() => {
        this.$router.go(0);
      })
    },
    //权限1点击 确定询单
    subButClick() {
      let data = {
        order_buyer_id: this.inquiryDetailedTile.order_buyer_id
      }
      newOrderPost2(data).then(() => {
        this.$router.go(0)
      })
    },
    //点击作废按钮
    delButClick() {
      let data = {
        id: this.inquiryDetailedTile.id
      };
      delNewOrder(data).then(() => {
        // this.leftData();
        this.$router.go(0)
      })
    },
    //表格切换采购员
    tableBuyerIdChange(val) {
      this.tableBuyerId = val;
      //报价截图
      let quoteAll = this.quoteAllData[this.tableBuyerId];
      this.inquiryDetailedTile.quoteScreenshotId = quoteAll.id;
      this.inquiryDetailedTile.quoteScreenshot = {
        img: quoteAll.payment_screenshot == null ? [] : quoteAll.payment_screenshot.toString().split(','),// 上传截图图片
        check: true // 是否销售可见 1-可见 2-不可见quoteAll.payment_screenshot_status == 1 ?
      };
      this.desc_buyer = quoteAll.desc_buyer//报价备注
      //报价附件
      this.inquiryDetailedTile.quotationAttachment = {
        attachment: quoteAll.payment_attachment == null ? [] : JSON.parse(quoteAll.payment_attachment),// 上传附件
        check: true // 是否销售可见quoteAll.payment_attachment_status == 1 ?
      };
      //每条的不一样采购员选择的报价 order_buyer_record
      for (let j = 0; j < this.inquiryDetailedTile.tableData.length; j++) {
        let list = [];
        let order_buyer_record = this.inquiryDetailedTile.tableData[j].order_buyer_record[this.tableBuyerId];
        this.inquiryDetailedTile.tableData[j].tableBuyerSelect = 0;
        //默认的第一个采购员还没报价
        if (order_buyer_record == undefined) {
          list = [{
            discountUnitPrice: '',//折后单价
            currency: '',//币种
            discount: '',//折扣
            totalPrice: '',//总价
            unitWeight: '',//单件重量
            unitOfWeight: '',//重量单位
            grossWeight: '',//总重量
            packageDimensions: '',//包装尺寸
            packingUnit: '',//包装单位
            deliveryTime: '',//货期
            deliveryUnit: '',//货期单位
          }]
        } else {
          for (let i = 0; i < order_buyer_record.length; i++) {
            let listData = {
              id: order_buyer_record[i].id,
              discountUnitPrice: order_buyer_record[i].purchase_money,//折后单价
              currency: order_buyer_record[i].currency,//币种
              discount: order_buyer_record[i].discount,//折扣
              totalPrice: order_buyer_record[i].total_money,//总价
              unitWeight: order_buyer_record[i].weight,//单件重量
              unitOfWeight: order_buyer_record[i].weight_unit,//重量单位
              grossWeight: order_buyer_record[i].weight_total,//总重量
              packageDimensions: order_buyer_record[i].pack_size,//包装尺寸
              packingUnit: order_buyer_record[i].pack_unit,//包装单位
              deliveryTime: order_buyer_record[i].delivery_date,//货期
              deliveryUnit: order_buyer_record[i].delivery_date_unit,//货期单位
              sort: order_buyer_record[i].sort,
            }
            list.push(listData)
          }
        }
        this.inquiryDetailedTile.tableData[j].list = list;
        // }
      }
      // 供应商
      if (this.supplierAllData[this.tableBuyerId] == undefined) {
        this.inquiryDetailedTile.supplierData = [];
      } else {
        let supplierData = this.supplierAllData[this.tableBuyerId][(this.inquiryDetailedTile.tableData[0].tableBuyerSelect) + 1];
        let supplier = {
          id: supplierData.id,
          supplier_name: supplierData.supplier_name, // 供应商名称
          contacts: supplierData.contacts,// 联系人
          job: supplierData.job, // 职务
          phone: supplierData.phone,// 手机号
          wx: supplierData.wx,// QQ/微信
          email: supplierData.email,// 邮箱
          sort: supplierData.sort == null ? 1 : supplierData.sort,//批次
        }
        this.inquiryDetailedTile.supplierData = [];
        this.inquiryDetailedTile.supplierData.push(supplier)
      }


    },


    //折后单价 选择第几个
    tableBuyerSelectChange(val, sort, row) {
      console.log(val)
      console.log(sort)
      console.log(row)
      for (let i = 0; i < this.inquiryDetailedTile.tableData.length; i++) {
        this.inquiryDetailedTile.tableData[i].tableBuyerSelect = val;
      }
      //切换供应商
      this.inquiryDetailedTile.supplierData = [];
      let supplierData = this.supplierAllData[this.tableBuyerId];
      let supplier = {
        id: supplierData[sort].id,
        supplier_name: supplierData[sort].supplier_name, // 供应商名称
        contacts: supplierData[sort].contacts,// 联系人
        job: supplierData[sort].job, // 职务
        phone: supplierData[sort].phone,// 手机号
        wx: supplierData[sort].wx,// QQ/微信
        email: supplierData[sort].email,// 邮箱
        sort: supplierData[sort].sort,//批次


        /* id: supplierData[val + 1].id,
         supplier_name: supplierData[val + 1].supplier_name, // 供应商名称
         contacts: supplierData[val + 1].contacts,// 联系人
         job: supplierData[val + 1].job, // 职务
         phone: supplierData[val + 1].phone,// 手机号
         wx: supplierData[val + 1].wx,// QQ/微信
         email: supplierData[val + 1].email,// 邮箱
         sort: supplierData[val + 1].sort,//批次*/
      }
      this.inquiryDetailedTile.supplierData.push(supplier)
    },

    //点击左边的列表 左边点击
    rightlistClick(key, id, order_id, supervisorBuyer_id, uid) {
      this.rightlistActive = key;
      if (this.$store.state.currentPermissions == 1) {
        if (this.$store.state.manager == 1) {
          this.rightData(order_id, supervisorBuyer_id, uid);
        } else {
          this.rightData(order_id, supervisorBuyer_id);
        }
      } else {
        this.rightData(id, supervisorBuyer_id);
      }
    },
    // 接口左边的数据
    leftData(id) {
      this.$store.state.loading = true;
      let params = {};
      switch (this.$route.name) {
        case 'inquiryList':
          if (this.$store.state.currentPermissions == 2) {
            params.seek_status = '1,2';
          } else {
            params.seek_status = '1';
          }
          params.id = id == undefined ? '' : id
          break
        case 'pendingQuotationList':
          params.seek_status = '2';
          params.id = id
          break
        case 'quotedList':
          params.seek_status = '3';
          params.id = id
          break
      }
      params.username = this.keyword;
      params.usertype = 1;
      params.page=this.leftCurrent_page
      leftNewOrder(params).then((result) => {
        this.leftCurPage=result.data.total;
        this.inquirylist = result.data.data.map((item) => {
          let order_buyer = '';
          for (let i = 0; i < item.order_buyer.length; i++) {
            order_buyer += item.order_buyer[i].buyer_name + ','
          }
          if (item.order_buyer_for_only == undefined) {
            item.order_buyer_for_only = []
          }
          return {
            created_at: this.Utils.timeDate(item.created_at),//创建时间
            seek_status: item.seek_status,//询单状态
            seek_no: item.seek_no,//询单号
            brand_ch: item.brand_ch,//品牌（中文）
            brand_en: item.brand_en, //品牌（英文）
            customer_name: item.customer_name,//客户名称
            buyer_id: order_buyer,//采购员
            salemanName:item.saleman.username,//销售名称
            id: item.id,
            order_id: item.order_id,
            supervisorBuyer_id: item.buyer_id,//采购主管才要用的
            leftOrder_buyer_for_onlyName: item.order_buyer_for_only.length == 0 ? '' : item.order_buyer_for_only[0].buyer_name,
            uid: item.order_buyer.length == 0 ? '' : item.order_buyer[0].id
          };
        });
        if (this.$store.state.currentPermissions == 1) {
          if (this.$store.state.manager == 1) {
            this.rightData(result.data.data[0].order_id, result.data.data[0].buyer_id, result.data.data[0].order_buyer[0].id);
          } else {
            this.rightData(result.data.data[0].order_id, result.data.data[0].buyer_id);
          }
          // console.log(result.data.data[0].buyer_id) 采购主管才要用的采购id
        } else {
          this.rightData(result.data.data[0].id, result.data.data[0].buyer_id);
        }

      })
    },

    //权限3点击发送报价
    sendQuoteClick() {
      let data = '';
      let offer_list = [];
      let center_offer_list = [];
      //供应商
      let supplierData = this.inquiryDetailedTile.supplierData;
      for (let i = 0; i < this.inquiryDetailedTile.tableData.length; i++) {
        /* let tableListData = this.inquiryDetailedTile.tableData[i].list[this.inquiryDetailedTile.tableData[i].tableBuyerSelect];
         let tableData = this.inquiryDetailedTile.tableData[i];
         let order_buyer_record_id = tableListData.id;
         let tableList = {
           order_spec_id: tableData.id,
           //list
           purchase_money: tableListData.discountUnitPrice, //折后单价
           discount: tableListData.discount,//折扣
           currency: tableListData.currency,//币种
           total_money: tableListData.totalPrice,//采购总价
           weight: tableListData.unitWeight,//单件重量
           weight_unit: tableListData.unitOfWeight,//重量单位
           weight_total: tableListData.grossWeight,//总重量
           pack_size: tableListData.packageDimensions,//包装尺寸
           pack_unit: tableListData.packingUnit,//包装单位
           delivery_date: tableListData.deliveryTime,//货期
           delivery_date_unit: tableListData.deliveryUnit,//货期单位

           //供应商
           supplier_name: supplierData.supplier_name,//供应商名称
           contacts: supplierData.contacts,//联系人
           job: supplierData.job,//职务
           phone: supplierData.phone,//手机号
           wx: supplierData.wx,//QQ/微信
           email: supplierData.email,//email
           sort: supplierData.sort == undefined ? 1 : supplierData.sort,

           brand_ch: tableData.brand_ch,//品牌（中文）
           brand_en: tableData.brand_en,//品牌（英文）
           spec: tableData.spec,//品名规格型号
           number: tableData.number,//数量
         }*/


        let tableListData = this.inquiryDetailedTile.tableData[i].list;
        let tableData = this.inquiryDetailedTile.tableData[i];
        let order_buyer_record_id = '';
        for (let j = 0; j < tableListData.length; j++) {
          order_buyer_record_id = tableListData[this.inquiryDetailedTile.tableData[i].tableBuyerSelect].id;
          let tableList = {
            order_spec_id: tableData.id,
            //list
            purchase_money: tableListData[j].discountUnitPrice, //折后单价
            discount: tableListData[j].discount,//折扣
            currency: tableListData[j].currency,//币种
            total_money: tableListData[j].totalPrice,//采购总价
            weight: tableListData[j].unitWeight,//单件重量
            weight_unit: tableListData[j].unitOfWeight,//重量单位
            weight_total: tableListData[j].grossWeight,//总重量
            pack_size: tableListData[j].packageDimensions,//包装尺寸
            pack_unit: tableListData[j].packingUnit,//包装单位
            delivery_date: tableListData[j].deliveryTime,//货期
            delivery_date_unit: tableListData[j].deliveryUnit,//货期单位
            sort: tableListData[j].sort,

            //供应商
            supplier_name: supplierData.supplier_name,//供应商名称
            contacts: supplierData.contacts,//联系人
            job: supplierData.job,//职务
            phone: supplierData.phone,//手机号
            wx: supplierData.wx,//QQ/微信
            email: supplierData.email,//email


            brand_ch: tableData.brand_ch,//品牌（中文）
            brand_en: tableData.brand_en,//品牌（英文）
            spec: tableData.spec,//品名规格型号
            number: tableData.number,//数量
          }
          offer_list.push(tableList);
        }

        // offer_list.push(tableList);
        let center_offer_listAll = this.inquiryDetailedTile.tableData[i];
        let center_offer_listData = {
          id: center_offer_listAll.id,
          rate: center_offer_listAll.exchangeRate,//汇率
          change_money: center_offer_listAll.convertedUnitPrice, //换算单价（元）
          transport_money_intel: center_offer_listAll.internationalShipping, //国际运费
          service_charge: center_offer_listAll.bankFees,//银行手续费
          tax: center_offer_listAll.tariff,//关税
          add_tax: center_offer_listAll.vat,//增值税
          other_money: center_offer_listAll.otherFee,//其他费用
          transport_money_ch: center_offer_listAll.domesticFreight,//国内运费
          profit: center_offer_listAll.grossProfit,//毛利
          profit_rate: center_offer_listAll.profit_rate,//毛利率
          sale_money: center_offer_listAll.unitPrice,//销售单价
          sale_money_total: center_offer_listAll.totalSalePrice,//销售总价
          order_buyer_record_id: order_buyer_record_id
        }
        center_offer_list.push(center_offer_listData)
      }
      data = {
        order_id: this.inquiryDetailedTile.id,
        order_buyer_id: this.inquiryDetailedTile.quoteScreenshotId,
        // buyer_id: this.tableBuyerId,  //采购员id
        buyer_id: this.buyer.buyer_ids[0],
        select_sort: 1, //选中的报价批次
        offer_list: JSON.stringify(offer_list), // 报价集合
        supplier_list: JSON.stringify(supplierData), // 供应商集合
        is_history: this.inquiryDetailedTile.isHistoricalQuote ? 1 : 2, // 是否历史询单 1-是 2否（默认）
        history_date: this.inquiryDetailedTile.historicalQuoteDate / 1000, // 历史询单日期
        payment_screenshot: this.inquiryDetailedTile.quoteScreenshot.img.toString(),  // 报价截图
        payment_screenshot_status: this.inquiryDetailedTile.quoteScreenshot.check ? 1 : 2, // 报价截图
        payment_attachment: JSON.stringify(this.inquiryDetailedTile.quotationAttachment.attachment), // 报价附件
        payment_attachment_status: this.inquiryDetailedTile.quotationAttachment.check ? 1 : 2,  // 报价附件 采购是否可见
        center_offer_list: JSON.stringify(center_offer_list),
        desc_buyer: this.desc_buyer,//报价备注
        desc_center: this.desc_center//业务备注
      }
      if (this.$store.state.currentPermissions == 3) {
        data.buyer_id = this.tableBuyerId;
        // data.buyer_id = this.buyer.buyer_ids[this.inquiryDetailedTile.tableData[0].tableBuyerSelect];
        console.log(123)
        if(!this.inquiryDetailedTile.tableData[0].order_buyer_record[data.buyer_id]){
          this.$message.error('没有选择报价或暂无报价');
          return false;
        }
        data.select_sort = this.inquiryDetailedTile.tableData[0].order_buyer_record[data.buyer_id][this.inquiryDetailedTile.tableData[0].tableBuyerSelect].sort; //选中的报价批次
      }

      switch (this.$store.state.currentPermissions) {
        case 1:
          if (this.$route.name == "quotedList") {
            newOrderPost1(data).then(() => {
              this.$message.success('提交成功');
              this.$router.go(0)
            })
          } else {
            newOrderPut(data).then(() => {
              this.$message.success('提交成功');
              this.$router.go(0)
            })
          }

          break
          //成本中心 新询单列表
        case 3:

          if (this.$route.name == "quotedList" || this.$route.name == "pendingQuotationList") {
            offerWholePost(data).then(() => {
              this.$message.success('修改成功');
              this.$router.go(0)
            })
          } else {
            //成本中心，新询单列表报价
            offerWholePut(data).then(() => {
              this.$message.success('提交成功');
              this.$router.go(0)
            })
          }
          break
      }
    },

    // 右边数据
    rightData(id, supervisorBuyer_id, uid) {
      this.$store.state.loading = true;
      let params = null;
      if (this.$store.state.currentPermissions == 1 && this.$store.state.manager == 1) {
        params = {
          id: id,
          uid: uid
        };
      } else {
        params = {
          id: id
        };
      }

      /*  if(this.$store.state.currentPermissions==1){
          params = {
            order_id: id
          };
        }else{
           params = {
            id: id
          };
        }*/
      rightNewOrder(params).then((result) => {
        let res = result.data;
        //已报价后的采购员名称
        if (res.order_buyer_for_only.length == 0) {
          this.order_buyer_for_onlyName = '';
        } else {
          this.order_buyer_for_onlyName = res.order_buyer_for_only[0].buyer_name;
        }
        this.desc_saleman = res.desc_saleman;//询单备注
        this.desc_buyer = res.desc_buyer;//采购备注
        this.desc_center = res.desc_center;//成本中心备注
        //采购员
        this.tableBuyer = res.offer_buyer.map(item => ({
          id: item.id,
          name: item.username
        }));
        // 报价截图 附件全部
        this.quoteAllData = res.order_buyer;
        //供应商 全部信息
        this.supplierAllData = res.order_supplier;
        this.buyer.buyer_ids = JSON.parse(res.buyer_ids);  //采购员id
        if (this.$store.state.currentPermissions == 1 && this.$store.state.manager == 1) {
          let array = [];
          array.push(supervisorBuyer_id)
          this.buyer.buyer_ids = array;
          this.buyer_id_1 = {...this.buyer.buyer_ids}; //采购端修改采购员原始id
        } else if (this.$store.state.currentPermissions == 1) {
          let array = [];
          array.push(Number(window.sessionStorage.getItem('id')))
          this.buyer.buyer_ids = array;
          this.buyer_id_1 = {...this.buyer.buyer_ids};//采购端修改采购员原始id
        } else {
          this.buyer.buyer_ids = JSON.parse(res.buyer_ids)//采购员
        }

        this.inquiryDetailedTile.id = res.id;
        this.tableBuyerId = res.tableBuyerId == null ? this.tableBuyer[0].id : res.tableBuyerId;

        this.inquiryDetailedTile.seek_no = res.seek_no;//询单编号
        this.inquiryDetailedTile.seek_status = res.seek_status;//询单状态 1-待询单 2-待报价 3-已报价
        this.inquiryDetailedTile.created_at = this.Utils.timeDate(res.order_record_date);//创建时间
        this.inquiryDetailedTile.end_time = this.Utils.timeDate(res.end_time);//截止时间
        this.inquiryDetailedTile.customer_name = res.customer_name;//客户名称
        this.inquiryDetailedTile.contacts = res.contacts;//联系人
        this.inquiryDetailedTile.job = res.job;//职务
        this.inquiryDetailedTile.phone = res.phone;//手机号
        this.inquiryDetailedTile.saleman = res.saleman_id;//销售员 id
        this.inquiryDetailedTile.salemanName = res.saleman.username;//销售员 名称
        this.inquiryDetailedTile.wx = res.wx;//QQ/微信
        this.inquiryDetailedTile.email = res.email;//邮箱
        this.inquiryDetailedTile.offerDate = '这个没有数据';//报价日期
        this.inquiryDetailedTile.isHistoricalQuote = res.is_history == 1 ? true : false;// 是否是历史询单 is_history 1是2否
        this.inquiryDetailedTile.historicalQuoteDate = this.Utils.timeDate(res.history_date); //历史报价日期*!/
        this.inquiryDetailedTile.order_buyer_id = res.order_buyer_id;//确定询单要用
        this.select_sort = res.select_sort;//只有权限三有用
        //询单截图
        this.inquiryDetailedTile.screenshot = {
          img: res.screenshot == null ? '' : res.screenshot.toString().split(','),
          check: res.screenshot_status == 1 ? true : false, //  1-可见 2-不可见
        };
        //询单附件
        this.inquiryDetailedTile.attachment = {
          attachment: res.attachment == '' ? [] : JSON.parse(res.attachment),
          check: res.attachment_status == 1 ? true : false // 	是否销售可见 询单附件-采购 1-可见 2-不可见
        };
        // 报价截图 附件全部
        //报价截图
        let quoteAll = '';
        if (this.$store.state.currentPermissions != 2) {
          if (this.$store.state.currentPermissions == 3) {
            quoteAll = this.quoteAllData[this.tableBuyer[0].id];
            this.inquiryDetailedTile.quoteScreenshotId = this.quoteAllData[this.tableBuyer[0].id].id;
            this.desc_buyer = this.quoteAllData[this.tableBuyer[0].id].desc_buyer//报价备注
          } else if (this.$store.state.currentPermissions == 1 && this.$store.state.manager == 1) {
            quoteAll = this.quoteAllData[supervisorBuyer_id];
            this.inquiryDetailedTile.quoteScreenshotId = this.quoteAllData[supervisorBuyer_id].id;
            this.desc_buyer = this.quoteAllData[supervisorBuyer_id].desc_buyer//报价备注
          } else {
            quoteAll = this.quoteAllData[window.sessionStorage.getItem('id')];
            this.inquiryDetailedTile.quoteScreenshotId = this.quoteAllData[window.sessionStorage.getItem('id')].id;
            this.desc_buyer = this.quoteAllData[window.sessionStorage.getItem('id')].desc_buyer//报价备注
          }
          this.inquiryDetailedTile.quoteScreenshot = {
            img: quoteAll.payment_screenshot == null ? [] : quoteAll.payment_screenshot.toString().split(','),// 上传截图图片
            check: true // 是否销售可见 1-可见 2-不可见 quoteAll.payment_screenshot_status == 1 ?
          };
        } else {
          quoteAll = this.quoteAllData[supervisorBuyer_id];
          // supervisorBuyer_id 当前采购员id
          if (this.$store.state.currentPermissions == 2 && this.$route.name == 'quotedList') {
            this.desc_buyer = this.quoteAllData[supervisorBuyer_id].desc_buyer//报价备注
          }
          if (quoteAll == undefined) {
            this.inquiryDetailedTile.quoteScreenshot = {
              img: [],// 上传截图图片
              // check: quoteAll.payment_screenshot_status == 1 ? true : false // 是否销售可见 1-可见 2-不可见
              check: true
            };
          } else {
            this.inquiryDetailedTile.quoteScreenshot = {
              img: quoteAll.payment_screenshot == null ? [] : quoteAll.payment_screenshot.toString().split(','),// 上传截图图片
              // check: quoteAll.payment_screenshot_status == 1 ? true : false // 是否销售可见 1-可见 2-不可见
              check: true
            };
          }
        }
        //报价附件
        if (quoteAll == undefined) {
          this.inquiryDetailedTile.quotationAttachment = {
            attachment: [],
            check: true // 是否销售可见
          }
        } else {
          this.inquiryDetailedTile.quotationAttachment = {
            attachment: quoteAll.payment_attachment == null ? [] : JSON.parse(quoteAll.payment_attachment),// 上传附件
            // check: quoteAll.payment_attachment_status == 1 ? true : false // 是否销售可见
            check: true // 是否销售可见
          };
        }

        this.inquiryDetailedTile.tableData = res.order_spec.map((item) => {
          let list = [];
          if (item.order_buyer_record.length == 0) {
            list = [{
              discountUnitPrice: '',//折后单价
              currency: '',//币种
              discount: '',//折扣
              totalPrice: '',//总价
              unitWeight: '',//单件重量
              unitOfWeight: '',//重量单位
              grossWeight: '',//总重量
              packageDimensions: '',//包装尺寸
              packingUnit: '',//包装单位
              deliveryTime: '',//货期
              deliveryUnit: '',//货期单位
            }]
          } else {
            let order_buyer_record = '';
            /*  if (this.$store.state.currentPermissions == 3) {
                order_buyer_record = item.order_buyer_record[this.tableBuyer[0].id];
              } else{
                order_buyer_record = item.order_buyer_record[window.sessionStorage.getItem('id')];
              }*/
            if (this.$store.state.currentPermissions == 1 && this.$store.state.manager == 1) {
              order_buyer_record = item.order_buyer_record[supervisorBuyer_id];
            } else if (this.$store.state.currentPermissions == 1) {
              order_buyer_record = item.order_buyer_record[window.sessionStorage.getItem('id')];

            } else {
              order_buyer_record = item.order_buyer_record[this.tableBuyer[0].id];
            }
            //默认的第一个采购员还没报价
            if (order_buyer_record == undefined) {
              list = [{
                discountUnitPrice: '',//折后单价
                currency: '',//币种
                discount: '',//折扣
                totalPrice: '',//总价
                unitWeight: '',//单件重量
                unitOfWeight: '',//重量单位
                grossWeight: '',//总重量
                packageDimensions: '',//包装尺寸
                packingUnit: '',//包装单位
                deliveryTime: '',//货期
                deliveryUnit: '',//货期单位
              }]
            } else {
              /*  console.log(order_buyer_record)
                for(let i in order_buyer_record){
                  let listData = {
                    id: order_buyer_record[i].id,
                    discountUnitPrice: order_buyer_record[i].purchase_money,//折后单价
                    currency: order_buyer_record[i].currency,//币种
                    discount: order_buyer_record[i].discount,//折扣
                    totalPrice: order_buyer_record[i].total_money,//总价
                    unitWeight: order_buyer_record[i].weight,//单件重量
                    unitOfWeight: order_buyer_record[i].weight_unit,//重量单位
                    grossWeight: order_buyer_record[i].weight_total,//总重量
                    packageDimensions: order_buyer_record[i].pack_size,//包装尺寸
                    packingUnit: order_buyer_record[i].pack_unit,//包装单位
                    deliveryTime: order_buyer_record[i].delivery_date,//货期
                    deliveryUnit: order_buyer_record[i].delivery_date_unit,//货期单位
                  }
                  list.push(listData)
                }*/
              for (let i = 0; i < order_buyer_record.length; i++) {
                let listData = {
                  id: order_buyer_record[i].id,
                  discountUnitPrice: order_buyer_record[i].purchase_money,//折后单价
                  currency: order_buyer_record[i].currency,//币种
                  discount: order_buyer_record[i].discount,//折扣
                  totalPrice: order_buyer_record[i].total_money,//总价
                  unitWeight: order_buyer_record[i].weight,//单件重量
                  unitOfWeight: order_buyer_record[i].weight_unit,//重量单位
                  grossWeight: order_buyer_record[i].weight_total,//总重量
                  packageDimensions: order_buyer_record[i].pack_size,//包装尺寸
                  packingUnit: order_buyer_record[i].pack_unit,//包装单位
                  deliveryTime: order_buyer_record[i].delivery_date,//货期
                  deliveryUnit: order_buyer_record[i].delivery_date_unit,//货期单位
                  sort: order_buyer_record[i].sort
                }
                list.push(listData)
              }
            }
          }
          return {
            id: item.id,
            brand_ch: item.brand_ch, //品牌（中文）
            brand_en: item.brand_en, //品牌（英文）
            spec: item.spec, //品名规格型号
            number: item.number, //数量
            tableBuyerSelect: 0, //选完采购员选择了第几个报价 这个不要传给后台 折后单价选择

            exchangeRate: item.rate,//汇率
            convertedUnitPrice: item.change_money,//换算单价
            internationalShipping: item.transport_money_intel,//国际运费
            bankFees: item.service_charge,//银行手续费
            tariff: item.tax,//关税
            vat: item.add_tax,//增值税
            otherFee: item.other_money,//其他费用
            domesticFreight: item.transport_money_ch,//国内运费
            grossProfit: item.profit,//毛利
            profit_rate: item.profit_rate,//毛利率
            unitPrice: item.sale_money,//销售单价
            totalSalePrice: item.sale_money_total,//销售总价
            order_buyer_record: item.order_buyer_record,//所有采购报价都在里面

            list: list,//单前选择的采购报价
          };
        });

        // 供应商
        let supplierAllData = '';
        if (this.$store.state.currentPermissions == 3) {
          supplierAllData = this.supplierAllData[this.tableBuyerId];
        } else if (this.$store.state.currentPermissions == 1 && this.$store.state.manager == 1) {
          supplierAllData = this.supplierAllData[supervisorBuyer_id];
        } else {
          supplierAllData = this.supplierAllData[window.sessionStorage.getItem('id')];
        }
        if (supplierAllData == undefined) {
          this.inquiryDetailedTile.supplierData = [{
            id: '',
            supplier_name: '', // 供应商名称
            contacts: '',// 联系人
            job: '', // 职务
            phone: '',// 手机号
            wx: '',// QQ/微信
            email: '',// 邮箱
            sort: 1,
          }];
        } else {
          /* let supplierData = this.supplierAllData[this.tableBuyerId][(this.inquiryDetailedTile.tableData[0].tableBuyerSelect) + 1];
           let supplier = {
             id: supplierData.id,
             supplier_name: supplierData.supplier_name, // 供应商名称
             contacts: supplierData.contacts,// 联系人
             job: supplierData.job, // 职务
             phone: supplierData.phone,// 手机号
             wx: supplierData.wx,// QQ/微信
             email: supplierData.email,// 邮箱
             sort: supplierData.sort,//批次
           }
           this.inquiryDetailedTile.supplierData = [];
           this.inquiryDetailedTile.supplierData.push(supplier)*/

          let supplierData = '';
          if (this.$store.state.currentPermissions == 3) {
            supplierData = this.supplierAllData[this.tableBuyerId];
          } else if (this.$store.state.currentPermissions == 1 && this.$store.state.manager == 1) {
            supplierData = this.supplierAllData[supervisorBuyer_id];
          } else {
            supplierData = this.supplierAllData[window.sessionStorage.getItem('id')];
          }

          this.inquiryDetailedTile.supplierData = [];
          if (this.$store.state.currentPermissions == 3) {
            let supplier = {
              id: supplierData[this.select_sort].id,
              supplier_name: supplierData[this.select_sort].supplier_name, // 供应商名称
              contacts: supplierData[this.select_sort].contacts,// 联系人
              job: supplierData[this.select_sort].job, // 职务
              phone: supplierData[this.select_sort].phone,// 手机号
              wx: supplierData[this.select_sort].wx,// QQ/微信
              email: supplierData[this.select_sort].email,// 邮箱
              sort: supplierData[this.select_sort].sort,//批次
            }
            this.inquiryDetailedTile.supplierData.push(supplier)
          } else {
            for (let key in supplierData) {
              let supplier = {
                id: supplierData[key].id,
                supplier_name: supplierData[key].supplier_name, // 供应商名称
                contacts: supplierData[key].contacts,// 联系人
                job: supplierData[key].job, // 职务
                phone: supplierData[key].phone,// 手机号
                wx: supplierData[key].wx,// QQ/微信
                email: supplierData[key].email,// 邮箱
                sort: supplierData[key].sort,//批次
              }
              this.inquiryDetailedTile.supplierData.push(supplier)
            }
          }
        }
      })
    },
    //采购员  选择采购员关闭dialog还原数据
    buyerSelectClose() {
      this.buyer.buyerSelectDialog = false;
      // this.buyer = this.$options.data().buyer;
    },
  },
};
</script>
<style lang="scss">
.leftCurPage {
  position: fixed;
  width: 216px;
  height: 28px;
  bottom: 15px;
  background: #fff;

  .el-pager {
    background: #fff;

    li {
      margin: 0 !important;
      margin-right: 5px !important;
      min-width: 20px !important;
      height: 22px !important;
      line-height: 22px !important;
    }
  }
}
</style>
